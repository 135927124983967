import instance from "@/utils/axiosInstance";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { toastr } from "react-redux-toastr";
import { GET_STRIPE_INFO_API } from "@/utils/apiConstants";

export const useGetStripeInfo = (isAddCardClicked) => {
  const getStripeInfo = async () => {
    if (isAddCardClicked) {
      //カードの追加ボタンが押されたか判定
      try {
        const response = await instance.get(GET_STRIPE_INFO_API);
        return _.get(response, "data.data", {});
      } catch (error) {
        toastr.error("", _.get(error, "response.data.message", ""));
        return {
          message: _.get(error, "response.data.message", {}),
          success: false,
        };
      }
    } else {
      return {}; //空のオブジェクトを返す
    }
  };

  const query = useQuery({
    queryKey: [GET_STRIPE_INFO_API, isAddCardClicked],
    queryFn: getStripeInfo,
  });

  return query;
};
