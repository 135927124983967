import instance from "@/utils/axiosInstance";
import {
  DISABLE_PRODUCT_CART_POPUP_STATE,
  DISABLE_PRODUCT_CART_POPUP_STATE_SAGA,
  GET_PRODUCT_COMMENTS,
  GET_PRODUCT_COMMENTS_SAGA,
  PUT_PRODUCT_COMMENTS_SAGA,
  PUT_PRODUCT_IN_CART,
  PUT_PRODUCT_TO_CART_SAGA,
} from "./constants";
import _ from "lodash";
import { GET_PRODUCT_COMMENTS_API, POST_PRODUCT_TO_CART_API } from "@/utils/apiConstants";
import { toastr } from "react-redux-toastr";
import { put, takeLatest, call } from "redux-saga/effects";
import { formatDataForGtm } from "@/utils/gtmUtils";

function addToCartAPICall(payload) {
  // isLoading(true);
  return instance
    .post(POST_PRODUCT_TO_CART_API, payload.payload)
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        detail: _.get(error, "response.data.errors.title", {})[0],
        success: false,
      };
    });
}

const addToCart = function* (payload) {
  const productData = payload.payload.productData;
  const navigate = payload.payload.navigate;
  const origin = payload.payload.origin; // login or not
  const callerType = payload.payload?.callerType; // cart or checkout

  let response;
  try {
    response = yield call(addToCartAPICall, payload.payload);
    if (_.get(response, "result", false)) {
      yield put({
        type: PUT_PRODUCT_TO_CART_SAGA,
        payload: response,
      });

      window.dataLayer.push({
        event: "add_to_cart",
        event_scope: {
          currency: "JPY",
          ...formatDataForGtm(productData),
        },
      });

      if (callerType === "cart") {
        if (origin === "login") {
          navigate("/cart");
        } else {
          payload.payload.setCartPopupOpen(true);
        }
      }

      if (callerType === "checkout") {
        window.dataLayer.push({
          event: "quick_checkout",
          event_scope: {
            currency: "JPY",
            ...formatDataForGtm(productData),
          },
        });
        navigate("/checkout");
      }
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error(error);
  }
};

const disablePopupState = function* (payload) {
  try {
    yield put({
      type: DISABLE_PRODUCT_CART_POPUP_STATE_SAGA,
      payload: payload,
    });
  } catch (error) {
    console.error(error);
  }
};

function getProductCommentsAPICall(payload) {
  // isLoading(true);
  return instance
    .get(GET_PRODUCT_COMMENTS_API, {
      params: payload.payload,
    })
    .then((response) => {
      //   isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      //   isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getProductComments = function* (payload) {
  let response;
  try {
    response = yield call(getProductCommentsAPICall, payload);
    if (_.get(response, "result", false)) {
      yield put({
        type: PUT_PRODUCT_COMMENTS_SAGA,
        payload: { payload: response },
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error(error);
  }
};

function* productPreviewSaga() {
  yield takeLatest(PUT_PRODUCT_IN_CART, addToCart);
  yield takeLatest(DISABLE_PRODUCT_CART_POPUP_STATE, disablePopupState);
  yield takeLatest(GET_PRODUCT_COMMENTS, getProductComments);
}

export default productPreviewSaga;
