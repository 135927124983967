import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_MASTER_PREFECTURE_INFO,
  GET_MASTER_PREFECTURE_INFO_REDUCER,
  POST_USER_SHIPPING_DATA_DZONE_A20,
  PUT_USER_SHIPPING_DATA_DZONE_A21,
  PREFECTURE_INFO_DATA_DZONE,
  PREFECTURE_INFO_DATA_DZONE_REDUCER,
  GET_INFO_DZONE_PARTS_INFO_B5_SAGA,
  GET_INFO_DZONE_PARTS_INFO_B5_REDUCER,
  GET_INFO_DZONE_PARTS_NO_B5_SAGA,
  GET_INFO_DZONE_PARTS_NO_B5_REDUCER,
  GET_INFO_DZONE_PARTS_COLOR_B5_REDUCER,
  GET_INFO_DZONE_PARTS_COLOR_B5_SAGA,
  GET_INFO_DZONE_PARTS_LIST_INFO_B6_SAGA,
  GET_INFO_DZONE_PARTS_LIST_INFO_B6_REDUCER,
  SET_FAV,
  DEL_FAV,
} from "./constants";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import qs from "qs";
import {
  COMPANT_FIND_ZIP_CODE_API,
  POST_USER_SHIPPING_A20_API,
  PUT_USER_SHIPPING_A21_API,
  GET_PREFECTURE,
  SEARCH_PARTS_B5_API,
  GET_REQUEST_PARTS_NO_API,
  GET_COLORS_API,
  GET_PARTS_LIST_INFO_API,
  GET_PARTS_LIST_INFO_FOR_USER_API,
  GET_PARTS_LIST_INFO_API_WITH_MODEL_SPEC,
  GET_PARTS_LIST_INFO_API_WITH_MODEL_SPEC_FOR_USER_API,
  ADD_FAVOURITE_PRODUCT_API,
  DELETE_FAVOURITE_PRODUCT_API,
} from "@/utils/apiConstants";
import instance from "@/utils/axiosInstance";
import { formatDataForGtm } from "@/utils/gtmUtils";
import { getCookie } from "@/utils/cookieUtils";

function userMyPageInfoImageHandler(data, isLoading) {
  isLoading(true);
  return instance
    .get(COMPANT_FIND_ZIP_CODE_API, { params: data })
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getMasterPrefrenceInfoHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  try {
    const response = yield call(userMyPageInfoImageHandler, payload.payload.pincode, isLoading);
    if (_.get(response, "result", false)) {
      yield put({
        type: GET_MASTER_PREFECTURE_INFO_REDUCER,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  }
};

function postUserShippingInfo(data, isLoading) {
  isLoading(true);
  return instance
    .post(POST_USER_SHIPPING_A20_API, data)
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const postUserShippingInfoHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let response;
  try {
    response = yield call(postUserShippingInfo, payload.payload.data, isLoading);
    if (_.get(response, "result", false)) {
      toastr.success("", _.get(response, "message"));
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  }
};

function updateUserShippingInfo(data, isLoading) {
  isLoading(true);
  return instance
    .put(PUT_USER_SHIPPING_A21_API, data)
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const updateUserShippingInfoHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let response;
  try {
    response = yield call(updateUserShippingInfo, payload.payload.data, isLoading);
    if (_.get(response, "result", false)) {
      toastr.success("", _.get(response, "message"));
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  }
};

function getPrefecture(data, isLoading) {
  isLoading(true);
  return instance
    .get(GET_PREFECTURE, { params: data })
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getPrefectureHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let response;
  try {
    response = yield call(getPrefecture, payload.payload.data, isLoading);
    if (_.get(response, "result", false)) {
      yield put({
        type: PREFECTURE_INFO_DATA_DZONE_REDUCER,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  }
};

function getPartsInfos(data) {
  return instance
    .get(SEARCH_PARTS_B5_API, { params: data })
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getPartsInfohandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let response;
  try {
    if (isLoading && isLoading != null) isLoading(true);
    response = yield call(getPartsInfos, payload.payload.data);
    if (_.get(response, "result", false)) {
      yield put({
        type: GET_INFO_DZONE_PARTS_INFO_B5_REDUCER,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  } finally {
    if (isLoading && isLoading != null) isLoading(false);
  }
};

function getPartsNos(data) {
  return instance
    .get(GET_REQUEST_PARTS_NO_API, { params: data })
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getPartsNohandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  try {
    isLoading(true);
    const response = yield call(getPartsNos, payload.payload.data);
    if (_.get(response, "result", false)) {
      yield put({
        type: GET_INFO_DZONE_PARTS_NO_B5_REDUCER,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  } finally {
    isLoading(false);
  }
};

function getPartsColors(data) {
  return instance
    .get(GET_COLORS_API, { params: data })
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getPartsColorhandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let response;
  try {
    if (isLoading && isLoading != null) isLoading(true);
    response = yield call(getPartsColors, payload.payload.data);
    if (_.get(response, "result", false)) {
      yield put({
        type: GET_INFO_DZONE_PARTS_COLOR_B5_REDUCER,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  } finally {
    if (isLoading && isLoading != null) isLoading(false);
  }
};

function getPartsListInfoColors(data, nonMakerIdFlg) {
  //メーカー検索と部品検索の叩くAPIの条件分岐
  //GET_PARTS_LIST_INFO_API_WITH_MODEL_SPEC = 車体番号検索
  //GET_PARTS_LIST_INFO_API = メーカー検索
  const isLoggedIn = !!getCookie("access_token");
  const url = isLoggedIn
    ? nonMakerIdFlg
      ? GET_PARTS_LIST_INFO_API_WITH_MODEL_SPEC_FOR_USER_API
      : GET_PARTS_LIST_INFO_FOR_USER_API
    : nonMakerIdFlg
      ? GET_PARTS_LIST_INFO_API_WITH_MODEL_SPEC
      : GET_PARTS_LIST_INFO_API;

  return instance
    .get(url, {
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { encodeValuesOnly: true });
      },
    })
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getPartsListInfohandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let response;
  let nonMakerIdFlg = payload.payload.data.nonMakerIdFlg;
  try {
    isLoading(true);
    response = yield call(getPartsListInfoColors, payload.payload.data, nonMakerIdFlg);
    yield put({
      type: GET_INFO_DZONE_PARTS_LIST_INFO_B6_REDUCER,
      payload: response,
    });
  } catch (error) {
    toastr.error("エラーが発生しました。");
  } finally {
    isLoading(false);
  }
};

function addFavoriteProductSagaAPICall(payload) {
  return instance
    .post(ADD_FAVOURITE_PRODUCT_API, payload.payload)
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const addFavoriteProductSaga = function* (payload) {
  const accessToken = getCookie("access_token");
  if (!accessToken) window.location.href = "/auth/login";

  let response;
  try {
    response = yield call(addFavoriteProductSagaAPICall, payload.payload);
    if (_.get(response, "result", false)) {
      //gtmTag
      window.dataLayer.push({
        event: "add_to_wishlist",
        event_scope: {
          currency: "JPY",
          ...formatDataForGtm(payload.payload.productData),
        },
      });
      toastr.success("", _.get(response, "message"));
    } else {
      toastr.error("", _.get(response, "message"));
    }
    payload.payload.callableAction();
  } catch (error) {}
};

function deleteFavoriteProductSagaAPICall(payload) {
  return instance
    .delete(DELETE_FAVOURITE_PRODUCT_API, {
      params: payload.payload,
    })
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const deleteFavoriteProductSaga = function* (payload) {
  const accessToken = getCookie("access_token");
  if (!accessToken) window.location.href = "/auth/login";

  let response;
  try {
    response = yield call(deleteFavoriteProductSagaAPICall, payload.payload);
    if (_.get(response, "result", false)) {
      //gtmTag
      window.dataLayer.push({
        event: "remove_from_wishlist",
        event_scope: {
          currency: "JPY",
          ...formatDataForGtm(payload.payload.productData),
        },
      });
      toastr.success("", _.get(response, "message"));
    } else {
      toastr.error("", _.get(response, "message"));
    }
    payload.payload.callableAction();
  } catch (error) {}
};

function* partsSaga() {
  yield takeLatest(GET_MASTER_PREFECTURE_INFO, getMasterPrefrenceInfoHandler);
  yield takeLatest(POST_USER_SHIPPING_DATA_DZONE_A20, postUserShippingInfoHandler);
  yield takeLatest(PUT_USER_SHIPPING_DATA_DZONE_A21, updateUserShippingInfoHandler);
  yield takeLatest(PUT_USER_SHIPPING_DATA_DZONE_A21, updateUserShippingInfoHandler);
  yield takeLatest(PREFECTURE_INFO_DATA_DZONE, getPrefectureHandler);
  yield takeLatest(GET_INFO_DZONE_PARTS_INFO_B5_SAGA, getPartsInfohandler);
  yield takeLatest(GET_INFO_DZONE_PARTS_NO_B5_SAGA, getPartsNohandler);
  yield takeLatest(GET_INFO_DZONE_PARTS_COLOR_B5_SAGA, getPartsColorhandler);
  yield takeLatest(GET_INFO_DZONE_PARTS_LIST_INFO_B6_SAGA, getPartsListInfohandler);
  yield takeLatest(SET_FAV, addFavoriteProductSaga);
  yield takeLatest(DEL_FAV, deleteFavoriteProductSaga);
}

export default partsSaga;
