import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Grid, Stack, Button, Typography } from "@mui/material";
import PageTitle from "@/components/PageTitle";
import Breadcumb from "@/components/Breadcumb";
import { IoCartOutline } from "react-icons/io5";
import { GreyButton, OrangeButton } from "../PhaseBStyles/style";
import { useNavigate } from "react-router-dom";

export default function CartNoLoginEmpty() {
  const navigate = useNavigate();

  const handleButtonClick = (key) => {
    switch (key) {
      case "login":
        return navigate("/auth/login");
      case "searchProducts":
        return navigate("/");
      default:
        break;
    }
  };
  const moveToHomePage = () => {
    navigate("/");
  };

  return (
    <>
      <Grid container>
        <Breadcumb
          routes={[
            { name: "TOP", link: "/" },
            { name: "カート", link: "" },
          ]}
        />
        <PageTitle title="カート" />
      </Grid>
      <Grid container mt={2} justifyContent="center">
        <Grid item xs={12} md={5} textAlign="center">
          <OrangeButton
            className="font-weight-700"
            fullWidth
            onClick={() => handleButtonClick("login")}
          >
            ログイン・会員登録
          </OrangeButton>
          <div className="mt-5">
            <IoCartOutline className="icon-size-48 opacity-40 "></IoCartOutline>
          </div>
          <Typography className="font-size-24 opacity-80 mb-3 mt-2" component="h5" variant="h5">
            まだ商品がありません
          </Typography>
          <Typography className="font-size-14 opacity-80 mb-8" mb={8} component="h5" variant="h5">
            商品をカートに入れるとここに表示されます
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={8} md={3}>
          <GreyButton fullWidth className="font-weight-700" onClick={moveToHomePage}>
            商品を検索する
          </GreyButton>
        </Grid>
      </Grid>
    </>
  );
}
