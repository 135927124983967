import instance from "@/utils/axiosInstance";
import { GET_SUGGESTED_PRODUCT_LIST, PUT_SUGGESTED_PRODUCT_LIST_SAGA } from "./constants";
import _ from "lodash";
import {
  GET_SUGGESTED_PRODUCTS_API,
  GET_SUGGESTED_PRODUCTS_FOR_USER_API,
} from "@/utils/apiConstants";
import { toastr } from "react-redux-toastr";
import { put, takeLatest, call } from "redux-saga/effects";
import { getCookie } from "@/utils/cookieUtils";

function suggestedProductListAPICall(payload) {
  const isLoggedIn = !!getCookie("access_token");
  const url = isLoggedIn ? GET_SUGGESTED_PRODUCTS_FOR_USER_API : GET_SUGGESTED_PRODUCTS_API;

  // isLoading(true);
  return instance
    .get(url, {
      params: payload.payload,
    })
    .then((response) => {
      //   isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      //   isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const suggestedProductList = function* (payload) {
  let response;
  payload.payload.isLoading(true);
  try {
    response = yield call(suggestedProductListAPICall, payload.payload);
    if (_.get(response, "result", false)) {
      yield put({
        type: PUT_SUGGESTED_PRODUCT_LIST_SAGA,
        payload: {
          data: _.get(response, "data"),
          offset: payload.payload.payload.offset,
          callerType: payload.payload.payload.callerType,
        },
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error("error");
  }
  payload.payload.isLoading(false);
};

function* SuggestedProductSaga() {
  yield takeLatest(GET_SUGGESTED_PRODUCT_LIST, suggestedProductList);
}

export default SuggestedProductSaga;
