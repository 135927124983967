import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Radio,
  FormControlLabel,
  Stack,
  RadioGroup,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import visaCard from "@/assets/images/visa.png";
import { Link, useNavigate } from "react-router-dom";
import { GreyBorderInput, OrangeButton, CheckoutCustomButton } from "../PhaseBStyles/style";
import "../PhaseBStyles/style.css";
import "./checkout.css";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { getCardDetails } from "../Card/action";
import { GET_INFO_DZONE_SHIPPING_LIST_SAGA } from "../Admin/MyPage/MyPage/constants";
import { UPADTE_IN_CHARGE_IN_C2_ON_CHANGE } from "../Cart/Cart/constants";
import { AddCardDetailsUpdated } from "@/components/AddCardDetails/AddCardDetailsUpdated";
import jcbCard from "@/assets/images/JCB_Card.png";
import masterCard from "@/assets/images/mastercard.png";
import dinerCard from "@/assets/images/dinerCard.png";
import amexCard from "@/assets/images/amex.png";
import discoverCard from "@/assets/images/discover.png";
import { formatPinCode } from "@/utils/utils";
import MomentUtils from "@date-io/moment";
import "moment/locale/ja";
import { ShippingInfo } from "./ShippingInfo";
import { Controller } from "react-hook-form";
import { CartDeleteDialog } from "@/components/CartDeletDialog";
import { chackAndDeleteCart } from "../Cart/Cart/action";
import { InChargeEditDialog } from "./InChargeEditDialog";
import { getCookie } from "@/utils/cookieUtils";

//Stripe関連
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useGetStripeInfo } from "@/components/AddCardDetails/useAddCardQuery";
import ENVS from "@/constants/envs";

const moment = new MomentUtils();
export default function ShippingDetail(props) {
  const { errors } = props;

  //StripeのProvider
  const [isAddCardClicked, setIsAddCardClicked] = useState(false); //カード追加ボタンがクリックされたか否かのフラグ
  const { data: getStripeInfo, isLoading: getStripeInfoLoading } =
    useGetStripeInfo(isAddCardClicked); //stripe情報取得
  props.setGetStripeInfoLoading(getStripeInfoLoading);
  const stripePromise = loadStripe(ENVS.STRIPE_KEY);
  const options = getStripeInfo && {
    clientSecret: getStripeInfo?.client_secret ?? "",
    appearance: {
      theme: "night",
      labels: "floating",
    },
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Get Redux State Values
  const shipping = useSelector((state) => {
    return state.myPageInfoReducer.shippingList;
  });

  const myPageInfoData = useSelector((state) => {
    return state.myPageInfoReducer.myPageInfo;
  });

  const cardInfo = useSelector((state) => {
    return state.cardReducer;
  });

  const userType = useSelector((state) => {
    return state.myPageInfoReducer.type;
  });

  const deleteParts = useSelector((state) => {
    return state.cartReducer.deleteItems;
  });

  const stripe_customer_id =
    myPageInfoData && myPageInfoData.user && myPageInfoData.user.stripe_customer_id
      ? myPageInfoData.user.stripe_customer_id
      : "";

  // Filter Card Icons By Brand Name
  const cardIcons = [
    { id: 1, image: visaCard, brand: "visa" },
    { id: 2, image: masterCard, brand: "mastercard" },
    { id: 3, image: jcbCard, brand: "jcb" },
    { id: 4, image: amexCard, brand: "amex" },
    { id: 5, image: dinerCard, brand: "diners" },
    { id: 6, image: discoverCard, brand: "discover" },
  ];
  const { company_name, city, building, address, tel, prefecture, postal_code } =
    _.isEmpty(props.user) &&
    !_.isEmpty(props.myPageInfo) &&
    props.myPageInfo &&
    props.myPageInfo != null
      ? props.myPageInfo.user
      : props.user && props.user != null
        ? props.user
        : {
            company_name: "",
            city: "",
            building: "",
            address: "",
            tel: "",
            prefecture: "",
            postal_code: "",
          };
  const [addCard, setAddCard] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [inChargeEdit, setInChargeEdit] = useState(null);
  const [inChargeList, setInChargeList] = useState([]);
  const [inChargeSelected, setInChargeSelected] = useState(null);
  const [myPageInfo, setMyPageInfo] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(100);
  const [cards, setCards] = useState([]);
  const [shippingdates, setShippingdates] = useState([]);
  const [isDelete, setIsDelete] = useState(false);

  function getDatesInRange(startDate, endDate, exclude) {
    const date = new Date(startDate.getTime());
    const dates = [];
    // ✅ Exclude start date
    if (exclude) {
      date.setDate(date.getDate() + 1);
    }

    // ✅ Exclude end date
    while (date < endDate) {
      dates.push(moment.date(date).format("YYYY-MM-DD"));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  useEffect(() => {
    dispatch(chackAndDeleteCart());
    getShippingList();
    deleteParts.length && setIsDelete(true);
  }, []);

  useEffect(() => {
    var startDate = new Date(props?.deliveryList?.earliest_delivery_date);

    var dateArray = [];

    for (var i = 1; i <= 10; i++) {
      var date = new Date(startDate.getTime()); // startDateのコピーを作成
      date.setDate(startDate.getDate() + i); // i日後の日付を設定

      // 結果をYYYY-MM-DDの形式で取得
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2); // JavaScriptの月は0から始まる
      var day = ("0" + date.getDate()).slice(-2);
      dateArray.push(year + "-" + month + "-" + day);
    }
    setShippingdates(dateArray);
  }, [props.deliveryList]);

  useEffect(() => {
    if (getCookie("access_token")) {
      // Fetching user all card details
      getCardDetailsWithRefresh();
    }
  }, [myPageInfoData]);

  useEffect(() => {
    if (cardInfo && cardInfo.card && cardInfo.card != null && cardInfo.card.length) {
      setCards([...cardInfo.card]);
    }
  }, [cardInfo]);

  useEffect(() => {
    setInChargeList(props.inChargeList);
  }, [props.inChargeList]);

  useEffect(() => {
    setInChargeSelected(props.inChargeSelected);
  }, [props.inChargeSelected]);

  useEffect(() => {
    setMyPageInfo(props.myPageInfo);
  }, [props.myPageInfo]);

  useEffect(() => {
    deleteParts.length && setIsDelete(true);
  }, [deleteParts?.length]);

  const getCardDetailsWithRefresh = () => {
    let data = { stripe_customer_id: stripe_customer_id };
    if (stripe_customer_id) {
      dispatch(
        getCardDetails({
          data,
          navigate,
          isLoading: props.isLoading,
        })
      );
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    setInChargeEdit(null);
  };

  const handelOnChangeInCharge = (data) => {
    props.setInChargeSelected(data);
    let flg = _.findIndex(inChargeList, ["in_charge_id", data]);
    props.dispatch({
      type: UPADTE_IN_CHARGE_IN_C2_ON_CHANGE,
      payload: inChargeList[flg],
    });
  };
  const handleDesiredTransport = (e) => {
    props.setTransportSelected(e.target.value);
  };
  const onInchargeAdd = () => {
    setModalOpen(true);
  };

  const getShippingList = () => {
    props.dispatch({
      type: GET_INFO_DZONE_SHIPPING_LIST_SAGA,
      payload: {
        data: {
          offset: offset,
          limit: limit,
        },
        isLoading: props.isLoading,
        navigate,
      },
    });
  };

  const handelAddCard = () => {
    setIsAddCardClicked(true); //useGetStripeInfoのフラグ
    setAddCard(true);
  };

  const GetPaymentList = () => {
    return (
      <Controller
        name="cardId"
        control={props.control}
        render={({ field: { value, onChange } }) => (
          <FormControl sx={{ width: "100%" }} error={"cardId" in errors}>
            {errors?.cardId && <FormHelperText>{errors.cardId.message}</FormHelperText>}
            <RadioGroup name="card-details-group" {...{ value, onChange }}>
              <Grid container>
                {cards.length
                  ? cards.map((crds, i) => {
                      let isDefault = crds.IsDefault;
                      if (value && value === crds.card_id) {
                        isDefault = true;
                      } else if (value) {
                        isDefault = false;
                      }
                      return (
                        <Grid key={i} item xs={12} md={12}>
                          <Grid item xs={12} md={12}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              className="py-3"
                              sx={{ px: "2%" }}
                              data-cy={`card${i}`}
                            >
                              <Stack direction="row" alignItems="center">
                                <img
                                  alt="Card-thubmile-img"
                                  src={
                                    cardIcons && cardIcons.find((x) => x.brand === crds.brand).image
                                  }
                                  className="responsive"
                                  style={{ width: "3rem", height: "auto" }}
                                />
                                <div className="mx-2">
                                  <div className="font-size-14">
                                    {crds.brand.toUpperCase()}カード
                                  </div>
                                  <div className="font-size-12">････ {crds.last4}</div>
                                </div>
                                <div style={{ flexGrow: 1 }}></div>
                              </Stack>
                              <Stack direction="row" alignItems="center">
                                <div>
                                  {" "}
                                  <Link
                                    className="font-size-16 font-weight-700 font-color-orange no-underline cursor link-hover-red"
                                    to={"/mypage/card/" + crds.card_id}
                                    target="_blank"
                                    data-cy={`creditCardDetail${i}`}
                                  >
                                    詳細
                                  </Link>
                                </div>
                                <div className="mx-md-2 mx-0">
                                  {" "}
                                  <FormControlLabel
                                    value={crds.card_id}
                                    control={
                                      <Radio
                                        id={"card-select-" + crds.card_id}
                                        checked={isDefault} // isDefault を checked プロパティに使用
                                        sx={{
                                          "&.Mui-checked": {
                                            color: "#E55C5C",
                                          },
                                        }}
                                      />
                                    }
                                    label=""
                                  />{" "}
                                </div>
                              </Stack>
                            </Stack>
                          </Grid>
                          <div className="custom-border-bottom" />
                        </Grid>
                      );
                    })
                  : ""}
              </Grid>
            </RadioGroup>
          </FormControl>
        )}
      />
    );
  };

  return (
    <>
      <div className="add-card">
        {stripePromise && getStripeInfo?.client_secret && (
          <Elements stripe={stripePromise} options={options}>
            <AddCardDetailsUpdated
              open={addCard}
              setOpen={setAddCard}
              stripe_customer_id={stripe_customer_id}
              refresh={getCardDetailsWithRefresh}
              clientSecret={getStripeInfo?.client_secret ?? ""}
              setIsAddCardClicked={setIsAddCardClicked}
              isLoading={props.isLoading}
            />
          </Elements>
        )}
      </div>
      <InChargeEditDialog
        handleClose={handleClose}
        modalOpen={modalOpen}
        inChargeEdit={inChargeEdit}
        isLoading={props.isLoading}
        registerIncharge={props.registerIncharge}
      />
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" component="h6" className="font-size-24 opacity-80">
            お客様情報
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <hr></hr>
        </Grid>
      </Grid>

      {props.user && props.user.type != 3 && props.user.type != null && (
        <>
          <Grid container rowSpacing={1} data-cy="userName">
            <Grid item xs={4} md={3}>
              <Typography variant="p" component="p" className="font-size-14 opacity-80">
                御社名
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography variant="p" component="p" className="font-size-14 textBreak">
                {company_name}
              </Typography>
            </Grid>
            <Grid item xs={2} md={3}></Grid>
          </Grid>
          <hr></hr>
        </>
      )}

      <Grid container rowSpacing={1} alignItems="center" data-cy="address">
        <Grid item xs={4} md={3}>
          <Typography variant="p" component="p" className="font-size-14 opacity-80">
            住所
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography variant="p" component="p" className="font-size-14">
            〒 {formatPinCode(postal_code)}
          </Typography>
          <Typography variant="p" component="p" className="font-size-14 textBreak">
            {prefecture}
            {city}
            {address}
            {building}
          </Typography>
        </Grid>
        <Grid item xs={2} md={3}></Grid>
      </Grid>
      <hr></hr>

      <Grid container rowSpacing={1} alignItems="center" data-cy="phoneNumber">
        <Grid item xs={4} md={3}>
          <Typography variant="p" component="p" className="font-size-14 opacity-80">
            電話番号
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography variant="p" component="p" className="font-size-14">
            {formatPhoneNumber(tel)}
          </Typography>
        </Grid>
        <Grid item xs={2} md={3}></Grid>
      </Grid>
      <hr></hr>

      {props.user && props.user.type != 3 && props.user.type != null && (
        <>
          <Grid container rowSpacing={1} alignItems="center">
            <Grid item xs={4} md={3}>
              <Typography variant="p" component="p" className="font-size-14 opacity-80">
                担当者
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={8}
                  sx={{ paddingLeft: "0px !important" }}
                  className="checkout-select-container"
                >
                  {inChargeList?.length > 0 ? (
                    <Select
                      value={inChargeSelected}
                      displayEmpty
                      fullWidth
                      className="checkout-select font-size-14"
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={(e) => {
                        handelOnChangeInCharge(e.target.value);
                      }}
                      data-cy="selectIncharge"
                    >
                      {inChargeList.map((inc, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={inc.in_charge_id}
                            data-cy={`incharge${index}`}
                          >
                            {inc.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    "-"
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={4} className="checkout-btn">
                  <CheckoutCustomButton
                    className="edit-button font-weight-700 font-size-14 mt-2"
                    variant="contained"
                    onClick={() => {
                      onInchargeAdd();
                    }}
                    data-cy="addInchargeDialog"
                  >
                    担当者を追加する
                  </CheckoutCustomButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <hr></hr>
        </>
      )}

      {/* New block */}

      <Grid container rowSpacing={1} className="mt-5">
        <Grid item xs={12} md={12}>
          <Typography variant="h6" component="h6" className="font-size-24 opacity-80">
            運送情報
          </Typography>
        </Grid>
      </Grid>
      <hr></hr>

      <Grid container rowSpacing={1} alignItems="center">
        <Grid item xs={4} md={3}>
          <Typography variant="p" component="p" className="font-size-14 opacity-80">
            運送先
          </Typography>
        </Grid>
        <Grid item xs={8} md={9}>
          {props?.shippingInfo && (
            <ShippingInfo
              shippingInfo={props?.shippingInfo}
              setShippingInfo={props?.setShippingInfo}
              shipping={shipping}
              myPageInfo={myPageInfo}
              shippingPage={props.shippingPage}
            />
          )}
        </Grid>
      </Grid>
      <hr></hr>

      <Grid container rowSpacing={1} alignItems="center">
        <Grid item xs={4} md={3}>
          <Typography variant="p" component="p" className="font-size-14 opacity-80 ">
            運送希望日
          </Typography>
        </Grid>
        <Grid item xs={8} md={9}>
          <Grid container>
            <Grid item xs={12} md={8} className="checkout-select-container">
              <Select
                value={props.transportSelected}
                displayEmpty
                fullWidth
                className="font-size-14"
                inputProps={{ "aria-label": "Without label" }}
                onChange={(e) => handleDesiredTransport(e)}
                data-cy="selectShipping"
              >
                <MenuItem value={"最短"}>最短</MenuItem>
                {shippingdates &&
                  shippingdates.map((s, i) => (
                    <MenuItem key={i} value={s} data-cy={`shipping${i}`}>
                      {moment.date(s).format("MM月DD日 dddd")}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
              <Typography variant="p" component="p" className="font-size-12 opacity-80">
                ※場合によってはご希望日に届かない可能性があります
              </Typography>
              {props.itemLength > 1 && (
                <Typography variant="p" component="p" className="font-size-12 opacity-80">
                  ※複数商品を一度にご注文いただいた場合は、商品到着日がずれる場合がございます。
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr></hr>
        </Grid>
      </Grid>

      {/* New block */}

      <Grid container rowSpacing={1} className="mt-5">
        <Grid item xs={12} md={12}>
          <Typography variant="h6" component="h6" className="font-size-24 opacity-80">
            決済方法
          </Typography>
        </Grid>
      </Grid>
      <hr></hr>
      <Controller
        name="settlement"
        control={props.control}
        render={({ field: { value, onChange } }) => (
          <FormControl sx={{ width: "100%" }}>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              value={value}
              name="settlement-group"
              {...{ onChange }}
            >
              <Stack direction="row" justifyContent="space-between">
                <FormControlLabel
                  value="card"
                  control={
                    <Radio
                      disabled={cards.length ? false : true}
                      sx={{
                        "&.Mui-checked": {
                          color: "#E55C5C",
                        },
                      }}
                      data-cy="radioCard"
                    />
                  }
                  label="クレジットカード決済"
                />

                <CheckoutCustomButton
                  className="edit-button font-weight-700 font-size-13"
                  variant="contained"
                  fullWidth
                  onClick={handelAddCard}
                  data-cy="addCardDialogButton"
                >
                  カードを追加する
                </CheckoutCustomButton>
              </Stack>
              {props.settlement === "card" ? <GetPaymentList /> : null}
              {userType !== 3 && (
                <Stack direction="row">
                  <FormControlLabel
                    value="invoice"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "#E55C5C",
                          },
                        }}
                        data-cy="radioInvoice"
                      />
                    }
                    label={
                      <>
                        <Typography variant="p" component="p" className="font-size-14">
                          請求書発行
                        </Typography>
                        <Typography variant="p" component="p" className="font-size-12 opacity-80">
                          ※1ヶ月毎に請求書を発行いたします
                        </Typography>
                      </>
                    }
                  />
                </Stack>
              )}
            </RadioGroup>
          </FormControl>
        )}
      />
      {deleteParts?.length > 0 && (
        <CartDeleteDialog open={isDelete} setOpen={setIsDelete} cartItems={deleteParts} />
      )}
    </>
  );
}

export function formatPhoneNumber(phoneNumber) {
  // convert the raw number to (xxx) xxx-xxx format
  //const x = phoneNumber && phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  const length = phoneNumber?.length ?? 0;

  if (length === 11) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7, length)}`;
  } else if (length === 10) {
    return `${phoneNumber.slice(0, 2)}-${phoneNumber.slice(2, 6)}-${phoneNumber.slice(6, length)}`;
  } else {
    return "";
  }
}
