import instance from "@/utils/axiosInstance";
import { REGISTER_MYPAGE_WITHDRAW_SAGA, REGISTER_MYPAGE_WITHDRAW_ACTION } from "./constants";
import _ from "lodash";
import { GET_REQUEST_PARTS_INFO_API } from "@/utils/apiConstants";
import { toastr } from "react-redux-toastr";
import { put, takeLatest, call } from "redux-saga/effects";

let response;

function mypageWithdrawApiCall(payload) {
  // isLoading(true);
  return instance
    .get(GET_REQUEST_PARTS_INFO_API)
    .then((response) => {
      //   isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      //   isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const mypageWithdrawRegister = function* (payload) {
  try {
    response = yield call(mypageWithdrawApiCall, payload);
    if (_.get(response, "result", false)) {
      yield put({
        type: REGISTER_MYPAGE_WITHDRAW_SAGA,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error("catch --->");
  }
};

function* mypageWithdrawSaga() {
  yield takeLatest(REGISTER_MYPAGE_WITHDRAW_ACTION, mypageWithdrawRegister);
}

export default mypageWithdrawSaga;
