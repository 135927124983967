import React, { useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme.js";
import "./global.css";
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import Checkout from "@/views/Checkout/Checkout";
import CartEmpty from "@/views/Cart/CartEmpty";
import CartNoLoginEmpty from "@/views/Cart/CartNoLoginEmpty";
import { Box, Grid } from "@mui/material";
import CustomLoader from "@/components/Loader";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ScrollToTop from "@/components/ScrollToTop";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import { getCookie } from "@/utils/cookieUtils";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ENVS from "@/constants/envs.ts";

import Error from "@/views/Error";
import GtmProvider from "./libs/GtmProviders";

const Page404 = React.lazy(() => import("./views/page404/Page404"));
const Page500 = React.lazy(() => import("./views/page500/Page500"));
const Signup = React.lazy(() => import("./views/signup/signup"));
const SignupConfirm = React.lazy(() => import("./views/signup/signupConfirm"));
const SignupComplete = React.lazy(() => import("./views/signup/signupComplete"));
const SignupCode = React.lazy(() => import("./views/signup/singupCode"));
const Login = React.lazy(() => import("./views/Login/Login"));
const MyPageInfo = React.lazy(() => import("./views/Admin/MyPage/MyPage"));
const MyPageNoLogin = React.lazy(() => import("./views/UserProfile/MyPageNoLogin"));
const MyPagePerson = React.lazy(() => import("./views/Admin/MyPage/MyPagePerson"));
const MyPageSpTop = React.lazy(() => import("./views/Admin/MyPage/MyPageSpTop"));
const RegisterMail = React.lazy(() => import("./views/signup/registerEmail"));
const SignupType = React.lazy(() => import("./views/signup/signupType"));
const ResetPassword = React.lazy(() => import("./views/Login/resetPassword"));
const Members = React.lazy(() => import("./views/Admin/MyPage/Member/Member"));
const EmailUpdatePage = React.lazy(() => import("./views/EmailUpdate/EmailUpdatePage"));
const EmailInputCodePage = React.lazy(() => import("./views/EmailUpdate/EmailInputCodePage"));
const EmailUpdateCompletePage = React.lazy(
  () => import("./views/EmailUpdate/EmailUpdateCompletePage.jsx")
);
const ForgotPassword = React.lazy(() => import("./views/Login/forgotPassword.js"));
const Shipping = React.lazy(() => import("./views/Shipping/Shipping.js"));
const ShippingDetails = React.lazy(() => import("./views/Shipping/ShippingDetails.js"));
const MyProfile = React.lazy(() => import("./views/UserProfile/myProfile.js"));
const CodeInput = React.lazy(() => import("./views/Login/codeInput.js"));
const About = React.lazy(() => import("./views/About/About.tsx"));
const CarSelect = React.lazy(() => import("./views/CarSelect/index.jsx"));
const TypeSearch = React.lazy(() => import("./views/TypeSearch/index.jsx"));
const SearchRequest = React.lazy(() => import("./views/Search/searchRequest.js"));
const PartsNumberSearchResults = React.lazy(
  () => import("./views/Parts/PartsSearch/GenuinePartsNumberSearch/SearchResults.jsx")
);
const MakerSelect = React.lazy(() => import("./views/Search/MakerSelect/index.jsx"));
const ModelSelect = React.lazy(() => import("./views/ModelSelect/index.jsx"));
const ProductPreview = React.lazy(() => import("./views/Product/ProductPreview.js"));
const RequestConfirm = React.lazy(() => import("./views/Request/RequestConfirm.js"));
const RequestComplete = React.lazy(() => import("./views/Request/RequestComplete.js"));
const FavoriteProduct = React.lazy(() => import("./views/Favourite/FavoriteProduct.js"));
const Cart = React.lazy(() => import("./views/Cart/Cart.js"));
const PartsSelect = React.lazy(() => import("./views/Parts/PartsSelect/index.jsx"));
const RequestParts = React.lazy(() => import("./views/Request/Select/index.jsx"));
const MyPageCard = React.lazy(() => import("./views/UserProfile/MyPageCard.tsx"));
const MyPageHistoryDetails = React.lazy(() => import("./views/MyPage/History/Details.js"));
const MyPageCardDetails = React.lazy(() => import("./views/UserProfile/MyPageCardDetails.js"));
const PartsSearch = React.lazy(() => import("./views/Parts/PartsSearch/index.jsx"));
const MyPageWithdraw = React.lazy(() => import("./views/MyPage/MyPageWithdraw.js"));
const MyPageWithdrawError = React.lazy(() => import("./views/MyPage/MyPageWithdrawError.js"));
const MyPageWithdrawComplete = React.lazy(() => import("./views/MyPage/MyPageWithdrawComplete.js"));
const CheckoutComplete = React.lazy(
  () => import("./views/Checkout/CheckoutComplete/CheckoutComplete.js")
);
const CheckoutConfirm = React.lazy(
  () => import("./views/Checkout/CheckoutConfirm/CheckoutConfirm.js")
);
const SelectShipping = React.lazy(() => import("./views/Checkout/SelectShipping"));
const MyPageHistory = React.lazy(() => import("./views/History/MyPageHistory"));
const Notice = React.lazy(() => import("./views/Notice/Notice"));
const NoticeDetail = React.lazy(() => import("./views/Notice/NoticeDetail"));
const CautionPlateSearch = React.lazy(() => import("./views/CautionPlate/CautionPlateSearch"));
const SearchModify = React.lazy(() => import("./views/CautionPlate/SearchModify"));
const SearchModifypartTwo = React.lazy(() => import("./views/CautionPlate/SearchModifypartTwo"));
const Home = React.lazy(() => import("./views/Home/Home"));
const Term = React.lazy(() => import("./views/Terms/Term"));
const SecondhandNotice = React.lazy(() => import("./views/Terms/SecondhandNotice"));
const PrivacyPolicy = React.lazy(() => import("./views/Terms/PrivacyPolicy"));
const LegalNotice = React.lazy(() => import("./views/Terms/LegalNotice"));
const QrSearch = React.lazy(() => import("./views/CautionPlate/QrSearch"));
const Faq = React.lazy(() => import("./views/FAQ/Faq"));
const ContactsCompletePage = React.lazy(() => import("./pages/contacts/complete"));
const ContactsPage = React.lazy(() => import("./pages/contacts"));

import * as Sentry from "@sentry/react";
import CardIdProvider from "@/contexts/CardIdProvider";

Sentry.init({
  dsn: "https://818a30ecae9c48f3b832a8c6b5e1f12e@o1147142.ingest.sentry.io/4505039962570752",
  // normalizeDepth: 10,
  environment: "dev",

  ignoreErrors: [],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: ENVS.IS_PRODUCTION ? 0.2 : 1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
});

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">読み込み中...</div>
  </div>
);

const queryClient = new QueryClient();

// const worker = setupWorker(...getMock());
// worker.start();

function App() {
  const [showFooter, setShowFooter] = useState(true);
  const [showFooterVersion, setShowFooterVersion] = useState(false);
  const [isLoadingSpinner, setisLoadingSpinner] = useState(false);
  const stripePromise = loadStripe(ENVS.STRIPE_KEY);

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "page_view",
  });

  if (ENVS.IS_PRODUCTION) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        userId: getCookie("access_token"),
        host: window.location.host,
      });
    });
  }

  return (
    <QueryClientProvider client={queryClient}>
      <GtmProvider>
        <ThemeProvider theme={theme}>
          <Helmet></Helmet>
          <BrowserRouter>
            <CardIdProvider>
              <CssBaseline />
              <Box
                sx={{
                  display: "grid",
                  gridTemplateRows: "auto 1fr auto",
                  gridTemplateColumns: "100%",
                  minHeight: "100vh",
                }}
              >
                <Sentry.ErrorBoundary
                  fallback={({ resetError }) => {
                    return <Error resetError={resetError} />;
                  }}
                >
                  <NavBar />
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid
                      item
                      xs={12}
                      md={10}
                      className={
                        window.location.pathname == "/search/caution_plate" ||
                        window.location.pathname == "/search/qr"
                          ? ""
                          : "px-2"
                      }
                      sx={{ minHeight: "100%" }}
                    >
                      <React.Suspense fallback={loading}>
                        {isLoadingSpinner && <CustomLoader />}
                        {/* Scroll Web Page To Top */}
                        <ScrollToTop />
                        <Routes>
                          <Route
                            path="/500"
                            name="Page 500"
                            element={
                              <Page500
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                              />
                            }
                          />
                          <Route /**A-4 */
                            path="/auth/signup_input"
                            name="Signup"
                            element={
                              <Signup
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /**A-5 */
                            path="/auth/signup_confirm"
                            name="SignupConfirm"
                            element={
                              <SignupConfirm
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /**A-2 */
                            path="/auth/signup_code"
                            name="Signup"
                            element={
                              <SignupCode
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /** A-6 */
                            path="/auth/signup_complete"
                            name="SignupComplete"
                            element={
                              <SignupComplete
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          ></Route>
                          <Route /** A-1 */
                            path="/auth/signup"
                            name="signupmail"
                            element={
                              <RegisterMail
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /**A-3 */
                            path="/auth/signup_type"
                            name="Signuptype"
                            element={
                              <SignupType
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          ></Route>
                          <Route /**A-7 */
                            path="/auth/login"
                            name="login"
                            element={
                              <Login
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={setisLoadingSpinner}
                              />
                            }
                          />
                          <Route /** A-10 */
                            path="/auth/password_change/:token"
                            name="reset_password"
                            element={
                              <ResetPassword
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /**A-19 */
                            path="/auth/password_forget"
                            name="password_forget"
                            element={
                              <ForgotPassword
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /**A-23 */
                            path="/mypage/shipping"
                            name="Admin_My_Page"
                            element={
                              <Shipping
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /**A-8 */
                            path="/auth/code_input"
                            name="codeInput"
                            element={
                              <CodeInput
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /**A-20 */
                            path="/mypage/shipping_add/new"
                            name="ShippingDetails"
                            element={
                              <ShippingDetails
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /**A-21 */
                            path="/mypage/shipping_edit/edit"
                            name="ShippingEdit"
                            element={
                              <ShippingDetails
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /**A-17 */
                            path="/mypage/profile_edit"
                            name="MyProfile"
                            element={
                              <MyProfile
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /**A-22 */
                            path="/mypage/member"
                            name="members_My_Page"
                            element={
                              <Members
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /**A-24 */
                            path="/mypage/change"
                            name="email_change"
                            element={
                              <EmailUpdatePage
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /**A-25 */
                            path="/mypage/InputCode"
                            name="email_input_code"
                            element={
                              <EmailInputCodePage
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**A-26 */
                            path="/mypage/changeComplete"
                            name="email_change_complete"
                            element={
                              <EmailUpdateCompletePage
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**A-11 */
                            path="/mypage/no_login"
                            name="mypage_no_login"
                            element={
                              <MyPageNoLogin
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /* B-4 */
                            path="/search/type"
                            name="TypeSearch"
                            element={
                              <TypeSearch
                                setShowFooter={() => () => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /* B-9 */
                            path="/request"
                            name="SearchRequest"
                            element={
                              <SearchRequest
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /* B-1 */
                            path="/search/maker"
                            name="MakerSelect"
                            element={
                              <MakerSelect
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          {/* B-2 */}
                          <Route
                            path="/search/car"
                            name="carSelect"
                            element={
                              <CarSelect
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          {/* B-3 */}
                          <Route
                            path="/search/model"
                            name="modelSelect"
                            element={
                              <ModelSelect
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          {/* B-7 */}
                          <Route
                            path="/product/:item_id"
                            name="productDetails"
                            element={
                              <ProductPreview
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(true)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /* B-11 */
                            path="/request/confirm"
                            name="requestConfirm"
                            element={
                              <RequestConfirm
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /* B-13 */
                            path="/favorite"
                            name="favoriteProduct"
                            element={
                              <FavoriteProduct
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /* B-12 */
                            path="/request/complete"
                            name="requestComplete"
                            element={
                              <RequestComplete
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                              />
                            }
                          />
                          <Route
                            path="/cart" /**C-1 */
                            name="cart"
                            element={
                              <Cart
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(true)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            path="/cart/no-login-empty" /**C-1 Cart No-Login-Empty*/
                            name="cart-no-login-empty"
                            element={
                              <CartNoLoginEmpty
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            path="/cart/empty" /**C-1 Cart Empty*/
                            name="cart-empty"
                            element={
                              <CartEmpty
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**C-6 My Page card details */
                            path="/mypage/card/:id"
                            name="MyPageCardDetails"
                            element={
                              <MyPageCardDetails
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**D-2 My Page card */
                            path="/mypage/history_details"
                            name="MyPageHistoryDetails"
                            element={
                              <MyPageHistoryDetails
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**C-5 My Page card */
                            path="/mypage/card"
                            name="MyPageCard"
                            element={
                              <MyPageCard
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /** D-3 */
                            path="/mypage/withdraw"
                            name="mypage_withdraw"
                            element={
                              <MyPageWithdraw
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /** D-3 */
                            path="/mypage/withdraw/error"
                            name="mypage_withdraw_error"
                            element={
                              <MyPageWithdrawError
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                              />
                            }
                          />
                          <Route /** D-3 */
                            path="/mypage/withdraw/complete"
                            name="mypage_withdraw_complete"
                            element={
                              <MyPageWithdrawComplete
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                              />
                            }
                          />
                          <Route /** D-8 */
                            path="/notice"
                            name="notice"
                            element={
                              <Notice
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /** D-8 */
                            path="/notice/news"
                            name="notice-news"
                            element={
                              <Notice
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /** D-9 */
                            path="/notice/:id"
                            name="notice_detail"
                            element={
                              <NoticeDetail
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /** D-16 */
                            path="/term"
                            name="term"
                            element={
                              <Term
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                              />
                            }
                          />
                          <Route /** D-19 */
                            path="/secondhand_notice"
                            name="secondhand_notice"
                            element={
                              <SecondhandNotice
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                              />
                            }
                          />
                          <Route /** D-17 */
                            path="/privacy_policy"
                            name="privacy_policy"
                            element={
                              <PrivacyPolicy
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                              />
                            }
                          />
                          <Route /** D-18 */
                            path="/legal_notice"
                            name="legal_notice"
                            element={
                              <LegalNotice
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                              />
                            }
                          />
                          <Route
                            /**A-14 */
                            path="/mypage/about"
                            name="members_My_Page"
                            element={
                              <About
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                              />
                            }
                          />
                          <Route
                            /**A-12 */
                            path="/mypage/person"
                            name="Admin_My_Page_person"
                            element={
                              <MyPagePerson
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**A-12 A-13*/
                            path="/mypage/spTop"
                            name="Admin_My_Page_person"
                            element={
                              <MyPageSpTop
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                              />
                            }
                          />
                          <Route
                            /**A-12 */
                            path="/mypage"
                            name="Admin_My_Page"
                            element={
                              <MyPageInfo
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            path="/searchparts"
                            name="parts_select"
                            element={
                              <PartsSelect
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**B-10 */
                            path="/request/parts"
                            name="request_parts"
                            element={
                              <RequestParts
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**B-6 / B-8 */
                            path="/list"
                            name="request_search"
                            element={
                              <PartsSearch
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**B-6 / B-8 */
                            path="/list/search_parts_no"
                            name="request_search"
                            element={
                              <PartsNumberSearchResults
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**B-6 / B-8 */
                            path="/list/empty/no_login"
                            name="request_search"
                            element={
                              <PartsSearch
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**B-6 / B-8 */
                            path="/list/empty/login"
                            name="request_search"
                            element={
                              <PartsSearch
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**C-3*/
                            path="/checkout/confirm"
                            name="checkout_confirm"
                            element={
                              <Elements stripe={stripePromise} options={{ locale: "ja" }}>
                                <CheckoutConfirm
                                  setShowFooter={() => setShowFooter(true)}
                                  setShowFooterVersion={() => setShowFooterVersion(true)}
                                  isLoading={(flag) => {
                                    setisLoadingSpinner(flag);
                                  }}
                                />
                              </Elements>
                            }
                          />
                          <Route
                            /**C-4*/
                            path="/checkout/complete"
                            name="checkout_complete"
                            element={
                              <CheckoutComplete
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**C-4*/
                            path="/checkout/complete/:slug"
                            name="checkout_complete"
                            element={
                              <CheckoutComplete
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /**C-2 */
                            path="/checkout"
                            name="checkout"
                            element={
                              <Checkout
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(true)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route /**C-2 shipping */
                            path="/select/shipping"
                            name="select_shipping"
                            element={
                              <SelectShipping
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**D-1*/
                            path="/mypage/history"
                            name="mypage_history"
                            element={
                              <MyPageHistory
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**?*/
                            path="/faq"
                            name="faq"
                            element={
                              <Faq
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                              />
                            }
                          />
                          <Route
                            /**D-15*/
                            path="/contact/complete"
                            name="contact_complete"
                            element={<Navigate to="/contacts/complete" replace />}
                          />

                          <Route
                            path="/contacts/complete"
                            name="contacts_complete"
                            element={
                              <ContactsCompletePage
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                              />
                            }
                          />
                          <Route
                            path="/contact"
                            name="contact"
                            element={<Navigate to="/contacts" replace />}
                          />

                          <Route
                            path="/contacts"
                            name="contacts"
                            element={
                              <ContactsPage
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            /**D-10*/
                            path="/search/caution_plate"
                            name="caution_plate_search"
                            element={
                              isMobile ? (
                                <CautionPlateSearch
                                  setShowFooter={() => setShowFooter(false)}
                                  isLoading={(flag) => {
                                    setisLoadingSpinner(flag);
                                  }}
                                />
                              ) : (
                                <Page404
                                  setShowFooter={() => setShowFooter(true)}
                                  setShowFooterVersion={() => setShowFooterVersion(false)}
                                />
                              )
                            }
                          />
                          <Route
                            /**D-10*/
                            path="/search/caution_plate/read"
                            name="caution_plate_search"
                            element={
                              isMobile ? (
                                <CautionPlateSearch setShowFooter={() => setShowFooter(false)} />
                              ) : (
                                <Page404
                                  setShowFooter={() => setShowFooter(true)}
                                  setShowFooterVersion={() => setShowFooterVersion(false)}
                                />
                              )
                            }
                          />
                          <Route
                            /**D-11-2*/
                            path="/search/caution_plate/modify"
                            name="search_modify"
                            element={
                              isMobile ? (
                                <SearchModifypartTwo
                                  setShowFooterVersion={() => setShowFooterVersion(false)}
                                  isLoading={(flag) => {
                                    setisLoadingSpinner(flag);
                                  }}
                                />
                              ) : (
                                <Page404
                                  setShowFooter={() => setShowFooter(true)}
                                  setShowFooterVersion={() => setShowFooterVersion(false)}
                                />
                              )
                            }
                          />
                          <Route
                            /**D-11*/
                            path="/search/modify"
                            name="search_modify"
                            element={
                              isMobile ? (
                                <SearchModify
                                  setShowFooterVersion={() => setShowFooterVersion(false)}
                                />
                              ) : (
                                <Page404
                                  setShowFooter={() => setShowFooter(true)}
                                  setShowFooterVersion={() => setShowFooterVersion(false)}
                                />
                              )
                            }
                          />
                          <Route
                            /**D-12*/
                            path="/search/qr"
                            name="qr_search"
                            element={
                              isMobile ? (
                                <QrSearch setShowFooter={() => setShowFooter(false)} />
                              ) : (
                                <Page404
                                  setShowFooter={() => setShowFooter(true)}
                                  setShowFooterVersion={() => setShowFooterVersion(false)}
                                />
                              )
                            }
                          />
                          {/* Default to Home D-9 */}
                          <Route
                            name="Default"
                            path="/"
                            element={
                              <Home
                                setShowFooter={() => setShowFooter(true)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            }
                          />
                          <Route
                            path="*"
                            element={
                              <Page404
                                setShowFooter={() => setShowFooter(true)}
                                setShowFooterVersion={() => setShowFooterVersion(false)}
                              />
                            }
                          />
                        </Routes>
                      </React.Suspense>
                    </Grid>
                  </Grid>
                </Sentry.ErrorBoundary>
                {showFooter && <Footer id="footer" showFooterVersion={showFooterVersion} />}
              </Box>
            </CardIdProvider>
          </BrowserRouter>
        </ThemeProvider>
      </GtmProvider>
    </QueryClientProvider>
  );
}

export default App;
