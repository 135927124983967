import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import appLogo from "@/assets/images/appLogo.png";
import notificationIcon from "@/assets/images/notificationIcon.svg";
import cartIcon from "@/assets/images/cartIcon.svg";
import menuIcon from "@/assets/images/menuIcon.svg";
import favIcon from "@/assets/images/favIcon.svg";
import { Badge, Box, Grid, Stack } from "@mui/material";
import { FixedHeader, PopoverCustomized } from "@/views/PhaseBStyles/style";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Math } from "core-js";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { noticeReadCountApiCall } from "@/views/Notice/ApiService";
import { useState } from "react";
import { useLocationChange } from "../routerhook";
import { isMobile } from "react-device-detect";
import { getCookie } from "@/utils/cookieUtils";
import { useMemo } from "react";

const TYPE_OF_SEARCH = "components/constants/TYPE_OF_SEARCH";

export default function PrimarySearchAppBar() {
  const isLoggedIn = !!getCookie("access_token");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [anchorEl, setAnchorEl] = useState(false);

  const makerSelectNav = () => {
    navigate("/search/maker");
    dispatch({
      type: TYPE_OF_SEARCH,
      payload: false,
    });
  };

  const partsSelectNav = () => {
    navigate("/search/type");
    dispatch({
      type: TYPE_OF_SEARCH,
      payload: true,
    });
  };

  const favNavLink = () => {
    navigate("/favorite");
  };

  const noticeNavLink = () => {
    navigate("/notice");
  };

  const cartNavLink = () => {
    if (isLoggedIn) {
      navigate("/cart");
      return;
    }

    navigate("/cart/no-login-empty");
  };

  const HandleAppLogo = () => {
    navigate("/");
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };

  const isAuthURL =
    window.location.href.includes("auth") &&
    !window.location.href.includes("/auth/signup_complete");

  const id = open ? "simple-popover" : undefined;

  const getApiCount = async () => {
    const { response } = await noticeReadCountApiCall();

    if (response?.data.result == true) {
      setCount(response.data.unread_count);
    }
  };

  //loation change and rendering Navbar
  useLocationChange((location) => {
    // 通知数取得APIを呼び出す処理
    if (
      //  現在のURLがauth(ログインや新規登録)の場合は通知数取得APIは呼び出さない
      //　ただし、 "/auth/signup_complete" (会員登録登録完了画面)では呼び出す。
      isLoggedIn &&
      (!location.pathname.includes("auth") || location.pathname === "/auth/signup_complete")
    ) {
      getApiCount();
    }
  });

  return (
    <FixedHeader container className="fixedHeader">
      <Grid item xs={12} md={10} className="px-2 px-md-2">
        <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
          {!isAuthURL && (
            <div>
              <IconButton
                size="medium"
                color="inherit"
                sx={{ display: { xs: "block", md: "none" } }}
                disableRipple
                onClick={handleClick}
              >
                <div>
                  <img src={menuIcon} alt="menu icon" className="notification-bell opacity-60" />
                </div>
                <div className="font-size-10 opacity-60">メニュー</div>
              </IconButton>
            </div>
          )}
          <Box sx={{ mr: 5 }} className="logo" onClick={HandleAppLogo}>
            <img
              src={appLogo}
              alt="menuLogo"
              style={{ width: "70px", height: "32px", cursor: "pointer" }}
              data-cy="navBarLogo"
            />
          </Box>
          {!isAuthURL && (
            <Stack
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              className="mx-5"
            >
              <div
                className={`font-size-14 opacity-80 cursor p-4 headerLink`}
                onClick={() => {
                  window.location.pathname !== "/search/type" && partsSelectNav();
                }}
              >
                車体番号・類別型式から探す
              </div>
              <div
                style={{
                  border: "1px solid rgba(255,255,255,0.2)",
                  height: "32px",
                }}
              ></div>
              <div
                className={`font-size-14 opacity-80 cursor p-4 ${
                  window.location.pathname != "/search/maker" && "headerLink"
                }`}
                onClick={() => {
                  window.location.pathname != "/search/maker" && makerSelectNav();
                }}
              >
                メーカーから探す
              </div>
            </Stack>
          )}

          <div style={{ flexGrow: 1, minWidth: "1px" }}>
            <span></span>
          </div>
          {isAuthURL ? (
            <Stack sx={{ flexGrow: 1 }} />
          ) : (
            <Stack direction="row">
              {!isLoggedIn && (
                <Stack sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} direction="row">
                  <div
                    className={`font-size-14 opacity-80 cursor p-4 ${
                      window.location.pathname != "/search/maker" && "headerLink"
                    }`}
                    onClick={() => {
                      window.location.href = "/auth/login";
                    }}
                  >
                    ログイン・新規登録
                  </div>
                </Stack>
              )}
              <Grid className="cursor headerLink">
                <IconButton
                  size="medium"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  disableRipple
                  sx={{ display: { xs: "block", height: "72px" } }}
                  onClick={() => {
                    noticeNavLink();
                  }}
                >
                  <Badge badgeContent={count} color="error">
                    <img
                      alt="notification Icon"
                      src={notificationIcon}
                      className="notification-bell opacity-60"
                    />
                  </Badge>
                  <div className="font-size-10 opacity-60">お知らせ</div>
                </IconButton>
              </Grid>
              {isLoggedIn && (
                <>
                  <Grid className="cursor headerLink">
                    <IconButton
                      size="medium"
                      aria-label="show 17 new notifications"
                      disableRipple
                      color="inherit"
                      onClick={() => {
                        favNavLink();
                      }}
                      sx={{ display: { xs: "block", height: "72px" } }}
                    >
                      <div>
                        <img src={favIcon} alt="favIcon" className="notification-bell opacity-60" />
                      </div>
                      <div className="font-size-10 opacity-60">お気に入り</div>
                    </IconButton>
                  </Grid>
                  <Grid className="cursor headerLink">
                    <IconButton
                      size="medium"
                      aria-label="show 4 new mails"
                      disableRipple
                      color="inherit"
                      onClick={() => {
                        cartNavLink();
                      }}
                      sx={{ display: { xs: "block", height: "72px" } }}
                    >
                      <div>
                        <img
                          src={cartIcon}
                          alt="cartIcon"
                          className="notification-bell opacity-60"
                        />
                      </div>
                      <div className="font-size-10 opacity-60">カート</div>
                    </IconButton>
                  </Grid>
                </>
              )}
              <Grid className="cursor headerLink">
                <IconButton
                  size="medium"
                  aria-label="show 4 new mails"
                  className="header-menu-web-icon"
                  disableRipple
                  color="inherit"
                  sx={{ display: { xs: "none", md: "block", height: "72px" } }}
                  aria-describedby={id}
                  onClick={handleClick}
                >
                  <div>
                    <img src={menuIcon} alt="menuIcon" className="notification-bell opacity-60" />
                  </div>
                  <div className="font-size-10 opacity-60">メニュー</div>
                </IconButton>
              </Grid>
            </Stack>
          )}
        </Stack>
        <PopoverMenu
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          isLoggedIn={isLoggedIn}
        />
      </Grid>
    </FixedHeader>
  );
}

function PopoverMenu({ id, open, anchorEl, handleClose, isLoggedIn }) {
  const theme = useTheme();
  const requestPartsLink = isLoggedIn ? "/request" : "/auth/login";

  const matches = useMediaQuery(theme.breakpoints.only("xs"));
  const popupLinks = [
    {
      id: Math.random(),
      name: "コーションプレートから探す",
      displayInDesktop: isMobile,
      link: "/search/caution_plate",
    },
    {
      id: Math.random(),
      name: "車検証(QR)から探す",
      displayInDesktop: isMobile,
      link: "/search/qr",
    },
    {
      id: Math.random(),
      name: "車体番号・類別型式から探す",
      displayInDesktop: true,
      link: "/search/type",
    },
    {
      id: Math.random(),
      name: "メーカーから探す",
      displayInDesktop: true,
      link: "/search/maker",
    },
    {
      id: Math.random(),
      name: "部品を問い合わせる",
      displayInDesktop: true,
      link: requestPartsLink,
    },
    {
      id: Math.random(),
      name: "マイページTOP",
      displayInDesktop: isLoggedIn,
      link: matches ? "/mypage/spTop" : "/mypage",
    },
    {
      id: Math.random(),
      name: "購入履歴",
      displayInDesktop: isLoggedIn,
      link: "/mypage/history",
    },
    {
      id: Math.random(),
      name: "クレジットカード情報",
      displayInDesktop: isLoggedIn,
      link: "/mypage/card",
    },
    {
      id: Math.random(),
      name: "よくある質問",
      displayInDesktop: true,
      link: "/faq",
    },
    {
      id: Math.random(),
      name: "UZoneについて",
      displayInDesktop: true,
      link: "/mypage/about",
    },
  ];

  const firstMenu = popupLinks.slice(0, 5);
  const secondMenu = popupLinks.slice(5);
  const navigate = useNavigate();
  const popoverMenuPcHeight = useMemo(() => (isLoggedIn ? "600px" : "450px"), [isLoggedIn]);
  const popoverMenuMobileHeight = useMemo(() => (isLoggedIn ? "630px" : "490px"), [isLoggedIn]);

  const handelOnselect = (link) => {
    handleClose(); //サイドメニューを選択すると閉じる
    navigate(link);
  };

  return (
    <>
      {/* Popup item */}
      <PopoverCustomized
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          className: "navbar-popover-width popover-scroll",
          sx: { height: isMobile ? popoverMenuMobileHeight : popoverMenuPcHeight },
        }}
      >
        <Grid container className="navbar-responsive-padding">
          <Grid item xs={12} mb={2}>
            <Typography className="font-size-18-16-variable font-weight-700">商品を探す</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {firstMenu &&
                firstMenu.map((item, index) => {
                  return (
                    item.displayInDesktop && (
                      <Grid item xs={12} key={Math.random()}>
                        <Link
                          onClick={handleClose}
                          to={{ pathname: item?.link, state: { prevPath: location.pathname } }}
                          style={{ textDecoration: "none" }}
                          className="font-size-16-14-variable font-color-white "
                        >
                          <Stack
                            direction="row"
                            className=" sidebar-menu-hover border-bottom-sidebar-links"
                            sx={{ padding: "12px 0px" }}
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <div className="">{item.name}</div>
                            <div>
                              <FiChevronRight />
                            </div>
                          </Stack>
                        </Link>
                      </Grid>
                    )
                  );
                })}
            </Grid>
          </Grid>

          {/* second menu start */}
          <Grid item xs={12} mb={2} mt={5}>
            <Typography className="font-size-18-16-variable font-weight-700">マイページ</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {secondMenu &&
                secondMenu.map((item, index) => {
                  return (
                    item.displayInDesktop && (
                      <Grid item xs={12} key={Math.random()}>
                        <Box
                          onClick={() => {
                            handelOnselect(item?.link);
                          }}
                          style={{ textDecoration: "none" }}
                          className="font-size-16-14-variable font-color-white"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            className=" sidebar-menu-hover border-bottom-sidebar-links"
                            sx={{ padding: "12px 0px" }}
                          >
                            <div className="">{item.name}</div>
                            <div>
                              <FiChevronRight />
                            </div>
                          </Stack>
                        </Box>
                      </Grid>
                    )
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </PopoverCustomized>
    </>
  );
}
