import React, { useState, useEffect } from "react";
import _ from "lodash";
import "./checkout.css";
import { Grid, Button, Stack, Typography, Chip, Box, TextareaAutosize } from "@mui/material";
import CheckoutProduct from "./CheckoutProduct";
import ShippingDetail from "./ShippingDetail";
import CartPrice from "../Checkout/CartPrice";
import { useLocation, useNavigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import Breadcumb from "@/components/Breadcumb";
import { OrangeButton } from "../PhaseBStyles/style";
import "../PhaseBStyles/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_INFO_DZONE_CART_INFO_USER_C2_SAGA,
  UPADTE_IN_CHARGE_IN_C2_ON_CHANGE,
} from "../Cart/Cart/constants";
import { getInChargeList } from "../Admin/MyPage/MyPage/action";
import { POST_INCHARGE_A22 } from "../Admin/MyPage/MyPage/constants";
import { CheckoutPopup } from "@/components/Dialog";
import { getMyPageInfo } from "./../Admin/MyPage/MyPage/action";
import HelmetMeta from "@/components/HelmetMeta";
import { getcartInfo, getOrder, updateOrder } from "../Cart/Cart/action";
import { PUT_CARD_DETAILS_RESET } from "../Card/constants";
import { getCartdeliveryList } from "./action";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CheckoutCustomInputBase } from "../PhaseCStyles/style";
import CartPolicyDialog from "../Cart/CartPolicyDialog";
import { OrangeLinkButton } from "../PhaseBStyles/style";
import { useGetMyPageInfo } from "../Admin/MyPage/hooks/useGetMyPageInfo";
import { useGetInchargeList, useRegisterIncharge } from "../Admin/MyPage/hooks/useIncharge";
import { getCookie } from "@/utils/cookieUtils";

export default function Checkout(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { data: myPageInfo, isLoading: myPageInfoLoading } = useGetMyPageInfo(); //ユーザー情報取得
  const { data: inchargeList, isLoading: inchargeLoading } = useGetInchargeList(); //担当者リスト取得
  const { mutate: registerIncharge, isPending: registerInchargePending } = useRegisterIncharge(); //担当者登録
  const [inchargeSelectedLoadingFlag, setInchargeSelectedLoadingFlag] = useState(false);
  const [getStripeInfoLoading, setGetStripeInfoLoading] = useState(false);
  const overallLoading =
    myPageInfoLoading ||
    inchargeLoading ||
    registerInchargePending ||
    inchargeSelectedLoadingFlag ||
    getStripeInfoLoading;

  const schema = yup
    .object()
    .shape({
      contactMessage: yup.string().max(1024, "1024文字以内で入力してください。").nullable(),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      settlement: "invoice",
      cardId: "",
    },
    resolver: yupResolver(schema),
  });
  const watchedSettlement = watch("settlement");
  const watchedCardId = watch("cardId");

  // クレジットカードが登録されている場合はsettlementの初期値をcardに設定する
  const [creditExist, setCreditExist] = useState(false);
  const creditCard = useSelector((state) => state.cardReducer.card);
  const getCreditExist = () => {
    setCreditExist(creditCard ? true : false);
    if (creditCard) {
      let defaultCard = _.find(creditCard, { IsDefault: true });
      // set default checked card
      if (defaultCard) {
        setValue("cardId", defaultCard.card_id);
      }
    }
  };

  // useSelector(非同期関数)が更新された時にクレジットカードの有無を更新
  useEffect(() => {
    getCreditExist();
  }, [creditCard]);
  // クレジットカード情報が存在していて取得できた時にフォームのdefaultValueに追加
  useEffect(() => {
    if (creditExist) {
      setValue("settlement", "card");
    } else {
      setValue("settlement", "invoice");
    }
  }, [creditExist, reset]);

  const [open, setOpen] = useState(false);
  const [cartAmount, setCartAmount] = useState({
    total_price: 0,
    total_sales_price: 0,
    total_sales_tax: 0,
    total_shipping_rates: 0,
  });
  const [cartItems, setCartItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(100);
  const [inChargeSelected, setInChargeSelected] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [carNumberModal, setCarNumberModal] = useState(false);
  const [carRegexModal, setCarRegexModal] = useState(false);
  const [textArea, setTextArea] = useState("");
  const [transportSelected, setTransportSelected] = useState("最短");
  const [returnRefundOpen, setReturnRefundOpen] = useState(false);

  const cartInfo = useSelector((state) => {
    return state.cartReducer.cartInfo;
  });

  const user = useSelector((state) => {
    return state.cartReducer.user;
  });

  const in_charge = useSelector((state) => {
    return state.cartReducer.in_charge;
  });

  const deliveryList = useSelector((state) => {
    return state.cartReducer.deliveryList;
  });

  const myPageInfoData = useSelector((state) => {
    return state.myPageInfoReducer.myPageInfo;
  });

  const shipping = useSelector((state) => {
    return state.myPageInfoReducer.shippingList;
  });

  const selectShipping = useSelector((state) => {
    return state.shippingReducer.editMyPageShipping;
  });

  const defaultShipping = myPageInfoData?.shipping?.filter((el) => el.is_default === 1)[0];

  const [shippingInfo, setShippingInfo] = useState(defaultShipping);

  const getCartInfoInit = () => {
    dispatch(
      getcartInfo({
        params: {
          shipping_to_prefecture_id: shippingInfo?.prefecture_id,
        },
        isLoading: props.isLoading,
        navigate,
      })
    );
  };

  const getMyPageInfoInit = () => {
    dispatch(
      getMyPageInfo({
        params: null,
      })
    );
  };

  useEffect(() => {
    //billing
    dispatch(getOrder());
    if (!getCookie("access_token")) {
      navigate("/mypage/no_login");
      return;
    }

    dispatch(
      getMyPageInfo({
        params: null,
      })
    );

    getMyPageInfoInit();
    getCartInfoInit();
    // 購入時に使用するユーザー情報とデフォルトの担当者情報(おそらく最後に登録した担当者)を取得
    getCartUserInfo();
    getInChargeListHandler();
    if (location.state && location.state.textArea) {
      setTextArea(props.location.state.textArea); //購入確認から戻った時、連絡事項に代入
    }
    return () => {
      dispatch({
        type: PUT_CARD_DETAILS_RESET,
      });
    };
  }, []);

  useEffect(() => {
    if (cartInfo?.data) {
      let amt = cartAmount;
      let { total_price, total_sales_price, total_sales_tax, total_shipping_rates, items } =
        cartInfo.data[0];
      amt.total_price = total_price;
      amt.total_sales_price = total_sales_price;
      amt.total_sales_tax = total_sales_tax;
      amt.total_shipping_rates = total_shipping_rates;
      setCartAmount({ ...amt });
      setCartItems([...items]);
      dispatch(
        getCartdeliveryList({
          data: {
            item_id: grtItems(),
            shipping_to_prefecture_id: shippingInfo?.prefecture_id ?? "",
          },
          isLoading: props.isLoading,
        })
      );
      if (items?.length) {
        //gtmTag
        const itemName = [];
        const itemId = [];
        const itemPrice = [];
        const itemBrand = [];
        const itemCategoryName = [];
        const itemPartsName = [];
        const itemAffiliation = [];

        cartInfo.data[0].items.forEach((items) => {
          itemName.push(items.item_title);
          itemId.push(items.item_id);
          itemPrice.push(items.sales_price);
          itemBrand.push(items.maker_name);
          itemCategoryName.push(items.category_name);
          itemPartsName.push(items.parts_name);
          itemAffiliation.push("");
        });

        window.dataLayer.push({
          event: "begin_checkout",
          event_scope: {
            currency: "JPY",
            value: total_price ?? "",
            items: {
              item_name: itemName ?? "",
              item_id: itemId ?? "",
              price: itemPrice ?? "",
              item_category: itemCategoryName ?? "",
              item_category2: itemPartsName ?? "",
            },
          },
        });
      }
    }
  }, [cartInfo]);

  //選択された担当者を表示する
  useEffect(() => {
    if (!in_charge || !inchargeList?.length > 0) {
      setInChargeSelected("");
      return;
    }
    setInchargeSelectedLoadingFlag(true);
    const selectedInchargeId = in_charge?.in_charge_id; //表示される担当者のID
    const matchObject = inchargeList.find((obj) => obj.in_charge_id === selectedInchargeId); //削除されてないか確認
    if (matchObject) {
      setInChargeSelected(matchObject.in_charge_id);
      setTimeout(() => {
        setInchargeSelectedLoadingFlag(false);
      }, [700]);
    } else {
      setInChargeSelected(inchargeList[0]?.in_charge_id);
      dispatch({
        type: UPADTE_IN_CHARGE_IN_C2_ON_CHANGE,
        payload: inchargeList[0],
      });
      setTimeout(() => {
        setInchargeSelectedLoadingFlag(false);
      }, [700]);
    }
  }, [in_charge, inchargeList]);

  // 配送先を変更した時の処理
  useEffect(() => {
    selectShipping && setShippingInfo(selectShipping);
  }, [selectShipping?.shipping_id]);

  useEffect(() => {
    if (cartInfo && cartInfo.data && cartInfo.data.length) {
      let amt = cartAmount;
      let { total_price, total_sales_price, total_sales_tax, total_shipping_rates, items } =
        cartInfo.data[0];
      amt.total_price = total_price;
      amt.total_sales_price = total_sales_price;
      amt.total_sales_tax = total_sales_tax;
      amt.total_shipping_rates = total_shipping_rates;
      setCartAmount({ ...amt });
      setCartItems([...items]);
      dispatch(
        getCartdeliveryList({
          data: {
            item_id: grtItems(),
            shipping_to_prefecture_id: shippingInfo?.prefecture_id ?? "",
          },
          isLoading: props.isLoading,
        })
      );
    }
  }, [cartInfo]);

  useEffect(() => {
    dispatch(
      getcartInfo({
        params: {
          shipping_to_prefecture_id: shippingInfo?.prefecture_id,
        },
        isLoading: props.isLoading,
        navigate,
      })
    );
  }, [shippingInfo?.prefecture_id]);

  const getOrderstatus = () => {
    dispatch(getOrder());
  };
  const getSelectedCard = () => {
    const selectedCard = creditCard?.find((card) => card.card_id === watchedCardId);
    return selectedCard ? selectedCard : {};
  };

  const redirectionPage = () => {
    navigate("/checkout/confirm", {
      state: {
        paymentType: watchedSettlement,
        textArea,
        transportSelected,
        deliveryList,
        shipping: shippingInfo,
        cardDetails: getSelectedCard(),
      },
    });
  };

  const nextPage = handleSubmit((data) => {
    // 購入担当者が設定されていない場合にエラートーストを表示
    if (!inChargeSelected || !in_charge) {
      toastr.error("エラーが発生しました。", "担当者を選択してください。");
      return;
    }

    //それぞれの要素に、keyとvalueを付与
    const array = Object.entries(data).map(([key, value]) => ({ key, value }));
    //車体番号
    const carNumber = array.filter(
      (el) => el.key.indexOf("former") !== -1 || el.key.indexOf("latter") !== -1
    );

    //それぞれの要素をオブジェクトとして格納する
    var arr = [];
    for (var i = 0; i < carNumber.length; i = i + 2) {
      arr.push({
        user_car_model_name: carNumber[i].value,
        user_vehicle_no: carNumber[i + 1].value,
      });
    }
    arr.map((vehicle, i) => {
      vehicle["item_id"] = cartInfo.data[0].items[i].item_id;
    });

    //車体番号が空白か否か
    const emptyNumber = carNumber.filter((el) => el.value === "");

    //車体番号の前半のフィルター
    const formerNumber = array.filter((el) => el.key.indexOf("former") !== -1);
    //車体番号の　後半のフィルター
    const latterNumber = array.filter((el) => el.key.indexOf("latter") !== -1);

    //半角英数字で空文字以外を炙り出す正規表現（車体番号前半）
    const formerNumberRegex = formerNumber.filter((el) => !el.value.match(/^[0-9a-zA-Z]+$/));
    //半角数字で0~7文字数以外を炙り出す正規表現（車体番号後半）
    const latterNumberRegex = latterNumber.filter((el) => !el.value.match(/^([0-9]{0,7})$/));

    if (!data.settlement) {
      setDeleteModal(true);
    } else if (emptyNumber.length) {
      setCarNumberModal(true);
    } else if (formerNumberRegex.length || latterNumberRegex.length) {
      setCarRegexModal(true);
    } else if (watchedSettlement === "invoice") {
      getOrderstatus();
      dispatch(
        updateOrder({
          data: { items: arr },
          isLoading: true,
        })
      );
      redirectionPage();
    } else if (watchedSettlement === "card") {
      if (!getValues("cardId")) {
        setError("cardId", {
          message: "決済を行うカードを指定してください",
        });
        return;
      }
      dispatch(
        updateOrder({
          data: { items: arr },
          isLoading: true,
        })
      );
      redirectionPage();
    }
  });

  const shippingPage = () => {
    navigate("/select/shipping", {
      state: { referrer: "/shipping", ship: shipping },
    });
  };
  const getCartUserInfo = () => {
    dispatch({
      type: GET_INFO_DZONE_CART_INFO_USER_C2_SAGA,
      payload: {
        isLoading: props.isLoading,
        navigate,
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReturnRefundClose = () => {
    setReturnRefundOpen(false);
  };

  const handleReturnRefundClickOpen = () => {
    setReturnRefundOpen(true);
  };

  const getInChargeListHandler = () => {
    let payload = {
      search: "",
      order_by: "id",
      sort_order: "asc",
      paginate: 1,
      offset: offset,
      limit: limit,
    };
    dispatch(
      getInChargeList({
        data: payload,
        isLoading: props.isLoading,
        navigate,
      })
    );
  };

  const handelInchargeEdit = (obj) => {
    dispatch({
      type: POST_INCHARGE_A22,
      payload: {
        data: obj,
        isLoading: props.isLoading,
        navigate,
      },
    });
    setTimeout(() => {
      getInChargeListHandler();
    }, 1000);
  };

  const onDeleteOKClick = (data) => {
    setDeleteModal(false);
    setCarNumberModal(false);
    setCarRegexModal(false);
  };

  useEffect(() => {
    dispatch(
      getCartdeliveryList({
        data: {
          item_id: grtItems(),
          shipping_to_prefecture_id: shippingInfo?.prefecture_id,
        },
        isLoading: props.isLoading,
      })
    );
  }, [shippingInfo?.name]);

  const grtItems = () => {
    const item = [];
    if (cartInfo?.data?.length) {
      let itm = cartInfo.data[0].items;
      itm.map((itm) => {
        item.push(itm.item_id);
      });
    }
    return item.toString();
  };

  if (overallLoading) {
    props.isLoading(true);
  } else {
    props.isLoading(false);
  }

  return (
    <>
      <CartPolicyDialog
        open={returnRefundOpen}
        handleOpen={handleReturnRefundClickOpen}
        handleClose={handleReturnRefundClose}
      />
      <HelmetMeta
        title={"購入 | UZone - 自動車の中古部品販売"}
        description={""}
        showOpenGraphTags={false}
        showTwitterTags={false}
      ></HelmetMeta>
      <CheckoutPopup
        isOpen={deleteModal}
        submit={() => {
          onDeleteOKClick();
        }}
        title={`決済方法を選択してください。`}
      />
      <CheckoutPopup
        isOpen={carNumberModal}
        submit={() => {
          onDeleteOKClick();
        }}
        title={`全ての部品の車体番号を入力してください。`}
      />
      <CheckoutPopup
        isOpen={carRegexModal}
        submit={() => {
          onDeleteOKClick();
        }}
        title={"車体番号は前半を半角英数字で、後半を半角数字で入力してください"}
      />

      <Box className="checkout" component="form">
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
          <Grid item xs={12} md={9}>
            <Breadcumb
              routes={[
                { name: "TOP", link: "/" },
                { name: "カート", link: "/cart" },
                { name: "購入", link: "" },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack
              direction={{ xs: "row", sm: "row" }}
              spacing={{ xs: 1 }}
              alignItems="center"
              justifyContent="center"
              className="font-size-14"
              mt={2}
            >
              <Chip
                label="購入"
                className="font-size-14 border-radius-16 font-weight-700"
                sx={{ background: "rgba(229, 92, 92, 1)" }}
              />
              <span className="opacity-40">—</span>
              <Chip
                label="確認"
                className="font-size-14 border-radius-16  opacity-60"
                sx={{ background: "rgba(0,0,0,0)" }}
              />
              <span className="opacity-40">—</span>

              <Chip
                label="購入完了"
                className="font-size-14 border-radius-16 opacity-60"
                sx={{ background: "rgba(0,0,0,0)" }}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container mt="24px" columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
          <Grid item xs={12} md={9}>
            {user && (
              <ShippingDetail
                itemLength={cartItems.length}
                shippingPage={shippingPage}
                user={user}
                inChargeList={inchargeList}
                setInChargeSelected={setInChargeSelected}
                inChargeSelected={inChargeSelected}
                handelInchargeEdit={handelInchargeEdit}
                myPageInfo={myPageInfo}
                isLoading={props.isLoading}
                dispatch={dispatch}
                shippingInfo={shippingInfo}
                defaultShipping={defaultShipping}
                textArea={textArea}
                setTextArea={(t) => {
                  setTextArea(t);
                }}
                deliveryList={deliveryList}
                transportSelected={transportSelected}
                setTransportSelected={(d) => {
                  setTransportSelected(d);
                }}
                control={control}
                register={register}
                errors={errors}
                settlement={watchedSettlement}
                registerIncharge={registerIncharge}
                setGetStripeInfoLoading={setGetStripeInfoLoading}
              />
            )}
            <Typography variant="h6" component="h6" className="mt-4 mb-3 font-size-24 opacity-80">
              連絡事項
            </Typography>
            <TextareaAutosize
              {...register("contactMessage")}
              required
              fullWidth
              className="checkout-textarea font-size-14"
              placeholder="伝票に記載してもらいたい内容など、連絡事項があればこちらにご記入ください。"
              value={textArea}
              props
              onChange={(e) => {
                setTextArea(e.target.value);
              }}
              data-cy="contactMessage"
            />
            {errors?.contactMessage ? (
              <Typography
                style={{ color: "#E55C5C" }}
                className="font-size-14 opacity-60"
                htmlFor="bootstrap-input"
                mt={1}
              >
                {errors?.contactMessage.message}
              </Typography>
            ) : (
              ""
            )}

            <Typography component="h5" variant="h5" className="mt-4 mb-3 font-size-24 opacity-80">
              購入部品
            </Typography>

            {cartItems &&
              cartItems.map((product, idx) => {
                return (
                  <>
                    <Grid
                      container
                      rowSpacing={1}
                      className="mb-3 mt-4 py-2  checkout-product-grid d-flex align-items-center justify-content-left"
                      id="checkout-input-container"
                      sx={{ background: "#1F1F1F" }}
                    >
                      <Grid item xs={3} md={1.5}>
                        <Typography
                          variant="p"
                          component="p"
                          className="font-size-14 opacity-60"
                          textAlign={"right"}
                        >
                          車体番号 ：
                        </Typography>
                      </Grid>
                      <Grid item xs={3} md={3} style={{ paddingRight: "4px" }}>
                        <CheckoutCustomInputBase
                          inputProps={{ maxLength: 7 }}
                          required
                          placeholder="NCP81"
                          {...register(`former-${idx}`)}
                          defaultValue={product?.user_car_model_name ?? ""}
                          data-cy={`inputModelName${idx}`}
                        />
                      </Grid>
                      <Grid item xs={1} md={0.5}>
                        <Typography
                          variant="p"
                          component="p"
                          className="text-center"
                          style={{ width: "auto" }}
                        >
                          -
                        </Typography>
                      </Grid>
                      <Grid item xs={4.5} md={4.5} style={{ paddingLeft: "4px" }}>
                        <CheckoutCustomInputBase
                          inputProps={{ maxLength: 7 }}
                          required
                          fullWidth
                          autoComplete=""
                          placeholder="0000000"
                          {...register(`latter-${idx}`)}
                          defaultValue={product?.user_vehicle_no ?? ""}
                          data-cy={`inputVehicleNo${idx}`}
                        />
                      </Grid>
                    </Grid>
                    <CheckoutProduct cartProducts={product} key={idx} />
                  </>
                );
              })}
          </Grid>

          <Grid item xs={12} md={3}>
            <Box sx={{ position: "sticky", top: 130 }}>
              <div className="mt-5 d-none d-md-block"></div>
              <div className="d-block mt-2">
                <hr className="d-block mt-1"></hr>
              </div>
              <CartPrice
                btnTitle="内容の確認に進む"
                showCheckoutText={true}
                nextPage={handleSubmit(nextPage)}
                cartAmount={cartAmount}
                onClick={handleSubmit(nextPage)}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <div className="d-block d-md-none">
        <p className="mt-4 font-size-12 opacity-80 px-4">
          購入手続き後、すでにUZone以外で販売済みとなってしまい商品を確保できない場合がございます。
          欠品がある場合、メールにてご連絡致します。
        </p>
        <OrangeLinkButton
          disableRipple
          fullWidth
          className="mt-4 mb-5 font-weight-400 font-size-14 opacity-80 text-center cursor-pointer"
          onClick={() => {
            setReturnRefundOpen(true);
          }}
          data-cy="spPolicyTermsLink"
        >
          返品、返金について
        </OrangeLinkButton>
        <div
          style={{
            margin: "0px -10px",
            marginBottom: "-5rem",
            background: "rgba(31, 31, 31, 0.96)",
          }}
          className="px-4 py-3"
        >
          <OrangeButton
            sx={{
              position: "fixed",
              bottom: "0",
              left: "0",
              right: "0",
              margin: "10px",
              zIndex: 1,
            }}
            variant="contained"
            className="font-size-16 font-weight-700 "
            onClick={nextPage}
            data-cy="spNextPageButton"
          >
            内容の確認に進む
          </OrangeButton>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          <ClearIcon className="p-2 cursor " onClick={handleClose} sx={{ fontSize: 40 }} />
        </div>
        <DialogTitle id="alert-dialog-title">{"決算代行規約"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h6>お支払いについて</h6>
            <ul className="model-list">
              <li>法人/個人事業主を対象とした後払いサービスです。</li>
              <li>
                請求書は、株式会社マネーフォワード
                ケッサイ(以下「決済代行会社」といいます)からご購入(発注案件紹介申込に対する当社承諾がなされた日)の翌月第2営業日に発行されます。
              </li>
              <li>月額最大30万円までお取引可能です(月末締め翌月末請求書払い)。</li>
              <li> 請求書に記載されている銀行口座または、コンビニの払込票でお支払いください。</li>
              <li>請求代行のため代金債権は決済代行会社へ譲渡されます。</li>
              <div className="font-size-12 opacity-60 ">
                ※銀行振込の際の振込手数料はお客様にてご負担ください。
              </div>
            </ul>

            <div className="pt-3">
              <div className="opacity-100">決済代行会社による調査協力義務</div>
              <div className="font-size-14 opacity-80">
                当社は、ユーザーが利用登録をする際に氏名、生年月日、住所、電話番号、メールアドレス、銀行口座番号、クレジットカード番号、運転免許証番号などの個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を、当社の提携先（情報提供元、広告主、広告配信先などを含みます。以下｢提携先｣といいます。）などから収集することがあります。
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid item md={12} sm={12} xs={12} spacing={2} sx={{ mt: 2, mb: 1 }}>
            <Stack
              spacing={2}
              lg={12}
              xs={12}
              sm={9}
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ m: 0 }}
              className="dailog-action"
            >
              <Grid item md={6} xs={10} sm={10}>
                <Button variant="contained">同意する</Button>
              </Grid>
            </Stack>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
