import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_CARD_DETAILS,
  ADD_CARD_DETAILS,
  ADD_CARD_DETAILS_STATUS,
  PUT_CARD_DETAILS,
  PUT_CARD_AS_DEFAULT,
  PUT_SELECTED_CARD_AS_DEFAULT,
  DELETE_CARD,
} from "./constants";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import {
  GET_CARD_DETAILS_API,
  ADD_CARD_DETAILS_API,
  DELETE_CARD_API,
  SET_CARD_DEFUALT_API,
} from "@/utils/apiConstants";
import instance from "@/utils/axiosInstance";

function requestAPICall(API, payload, isLoading, requestMethod) {
  // Dynamic request method for API calls
  let req;
  if (requestMethod === "GET") {
    req = instance.get(API, { params: payload });
  } else if (requestMethod === "DELETE") {
    req = instance.delete(API, { params: payload });
  } else if (requestMethod === "PUT") {
    req = instance.put(API, payload);
  } else if (requestMethod === "POST") {
    req = instance.post(API, payload);
  }

  isLoading(true);
  return req
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getCardDetails = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  let requestMethod = "GET";
  try {
    response = yield call(
      requestAPICall,
      GET_CARD_DETAILS_API,
      payload.payload.data,
      isLoading,
      requestMethod
    );
    if (_.get(response, "result", false)) {
      yield put({
        type: PUT_CARD_DETAILS,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error("catch --->");
  }
};

const addCardDetails = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  let requestMethod = "POST";
  try {
    response = yield call(
      requestAPICall,
      ADD_CARD_DETAILS_API,
      payload.payload.data,
      isLoading,
      requestMethod
    );
    if (_.get(response, "result", false)) {
      yield put({
        type: ADD_CARD_DETAILS_STATUS,
        payload: {
          completed: true,
        },
      });
    } else {
      yield put({
        type: ADD_CARD_DETAILS_STATUS,
        payload: {
          completed: false,
        },
      });
    }
  } catch (error) {
    console.error("catch --->");
  }
};

const deleteCard = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  let navigate = payload.payload.navigate;
  let requestMethod = "DELETE";
  try {
    response = yield call(
      requestAPICall,
      DELETE_CARD_API,
      payload.payload.filteredData,
      isLoading,
      requestMethod
    );
    if (_.get(response, "result", false)) {
      if (payload.payload.filteredData.path) navigate(payload.payload.filteredData.path);
      else navigate("/mypage/card/");
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error("catch --->");
  }
};

const makeCardAsDefault = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  let requestMethod = "PUT";
  try {
    response = yield call(
      requestAPICall,
      SET_CARD_DEFUALT_API,
      payload.payload.filteredData,
      isLoading,
      requestMethod
    );
    if (_.get(response, "result", false)) {
      yield put({
        type: PUT_SELECTED_CARD_AS_DEFAULT,
        payload: payload.payload.filteredData,
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error("catch --->");
  }
};

function* cardSaga() {
  yield takeLatest(GET_CARD_DETAILS, getCardDetails);
  yield takeLatest(ADD_CARD_DETAILS, addCardDetails);
  yield takeLatest(DELETE_CARD, deleteCard);
  yield takeLatest(PUT_CARD_AS_DEFAULT, makeCardAsDefault);
}

export default cardSaga;
