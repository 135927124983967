import { combineReducers } from "redux";
import { registerReducer } from "@/views/registration/registerFormReducer";
import { signupReducer } from "@/views/signup/signupFormReducer";
import { myPageNoLoginReducer } from "@/views/UserProfile/MyPageNoLogin/myPageNoLoginReducer";
import { reducerForApiFail } from "@/views/Api/apiReducer";
import { myPageInfoReducer } from "@/views/Admin/MyPage/MyPage/myPageReducer";
import { shippingReducer } from "@/views/Shipping/Shipping/ShippingReducer";
import { reducer as toastrReducer } from "react-redux-toastr";
import { searchReducer } from "@/views/Search/searchReducer";
import { productPreviewReducer } from "@/views/Product/ProductPreviewReducer";
import { suggestedProductReducer } from "@/components/SuggestProducts/SuggestedProductReducer";
import { partsReducer } from "@/views/Parts/PartsUtils/PartsReducer";
import { favouriteProductReducer } from "@/views/Favourite/FavouriteProductReducer";
import { requestPartsReducer } from "@/views/Parts/RequestParts/RequestPartsReducer";
import { requestPartConfirmReducer } from "@/views/Request/RequestPartConfirmReducer";
import { cardReducer } from "@/views/Card/cardReducer";
import { orderHistoryReducer } from "@/views/History/MyPageHistoryRedux/orderHistoryReducer";
import { qrSearchReducer } from "@/views/CautionPlate/qrSearchReducer";
import { cartReducer } from "@/views/Cart/Cart/cartReducer";
import { cautionPlateReducer } from "@/views/CautionPlate/CautionPlateRedux/cautionPlateReducer";
import { homePageReducer } from "@/views/Home/HomePageReducer";
import { noticeReducer } from "@/views/Notice/NoticeDetailReducer";

const rootReducer = combineReducers({
  registerReducer,
  toastr: toastrReducer,
  signupReducer,
  myPageNoLoginReducer,
  myPageInfoReducer,
  shippingReducer,
  searchReducer,
  productPreviewReducer,
  suggestedProductReducer,
  partsReducer,
  favouriteProductReducer,
  requestPartsReducer,
  requestPartConfirmReducer,
  reducerForApiFail,
  cardReducer,
  cartReducer,
  orderHistoryReducer,
  qrSearchReducer,
  cautionPlateReducer,
  homePageReducer,
  noticeReducer,
});

export default rootReducer;
