import React from "react";
import logoUZonedarkUzone from "@/assets/images/logoUZonedarkUzone.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
IconButton;
import { IconButton, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function OrderImage(props) {
  const { imageWithId, productId, enableProductClick, imgPath, imageId, setImageId, scroll } =
    props;
  const navigate = useNavigate();
  const moveToProductDetails = (e) => {
    if (enableProductClick !== false) {
      navigate("/product/" + productId);
      e.stopPropagation();
    }
  };

  //配列にある画像のidとimageIdが一致する要素を返す（<img/>の画像選定）
  const findImage = () => {
    const imageFileter = imageWithId?.find((image) => image.id === imageId && image.src);
    return imageFileter.src;
  };

  const handleLeftScroll = () => {
    setImageId(imageId - 1);
  };
  const handleRightScroll = () => {
    setImageId(imageId + 1);
  };
  return (
    <React.Fragment>
      <Box
        style={{
          position: "relative",
          borderRadius: "8px",
        }}
      >
        <img
          data-cy="expandedImage"
          alt="product"
          // TODO: スライダーの拡大イメージと通常の表示（商品一覧など）で共通使用されているので処理が複雑になってしまっている。
          src={
            scroll === true
              ? imageWithId?.length > 0 && findImage()
                ? findImage()
                : logoUZonedarkUzone
              : imgPath
                ? imgPath
                : logoUZonedarkUzone
          }
          style={
            scroll === true
              ? imageWithId?.length > 0 && findImage()
                ? {
                    width: "100%",
                    height: "100%",
                    pointerEvents: "none",
                  }
                : {
                    padding: "22.5%",
                    background: "rgba(31, 31, 31, 1)",
                    width: "100%",
                    height: "auto",
                  }
              : imgPath
                ? {
                    width: "100%",
                    height: "100%",
                    pointerEvents: "none",
                  }
                : {
                    padding: "22.5%",
                    background: "rgba(31, 31, 31, 1)",
                    width: "100%",
                    height: "auto",
                  }
          }
          // 画像がエラーで読み込めなかった場合に代替画像を表示
          onError={(e) => {
            e.currentTarget.src = logoUZonedarkUzone;
            e.currentTarget.style.padding = "22.5%";
            e.currentTarget.style.background = "rgba(31, 31, 31, 1)";
            e.currentTarget.style.width = "100%";
            e.currentTarget.style.height = "auto";
          }}
          onClick={(e) => {
            moveToProductDetails(e);
          }}
          className={`${props.responsive} d-block cursor`}
        />

        {scroll === true && (
          <>
            <IconButton
              data-cy="leftIcon"
              component="a"
              disabled={imageId < 1}
              onClick={handleLeftScroll}
              sx={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "5%",
                color: "white",
                backgroundColor: " rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  color: "rgba(255, 255, 255, 0.5)",
                },
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>

            <IconButton
              data-cy="rightIcon"
              component="a"
              disabled={imageId >= imageWithId?.length - 1}
              onClick={handleRightScroll}
              sx={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                right: "5%",
                color: "white",
                backgroundColor: " rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  color: "rgba(255, 255, 255, 0.5)",
                },
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </>
        )}
      </Box>
    </React.Fragment>
  );
}
