import instance from "@/utils/axiosInstance";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { USER_MYPAGE_INFO_A12_API } from "@/utils/apiConstants";
import { getCookie } from "@/utils/cookieUtils";

//ユーザー情報取得
const getMyPageInfo = async () => {
  if (!getCookie("access_token")) return {};

  try {
    const response = await instance.get(USER_MYPAGE_INFO_A12_API);
    return _.get(response, "data.data", {});
  } catch (error) {
    return {
      message: _.get(error, "response.data.message", {}),
      success: false,
    };
  }
};

export const useGetMyPageInfo = () => {
  return useQuery({
    //ovalで作成されたuseQueryと干渉し予期せぬ動作をするためqueryKeyを変更
    //今後「/user/mypage/info」APIを使用する場合は　/src/apis/prev/user/user/user.ts　のものを使用してください。
    queryKey: ["/user/mypage/info2"],
    queryFn: getMyPageInfo,
  });
};
