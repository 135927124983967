import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Grid, Stack, Button, Typography } from "@mui/material";
import PageTitle from "@/components/PageTitle";
import Breadcumb from "@/components/Breadcumb";
import CartPolicyDialog from "./CartPolicyDialog";
import CartDeliveryDialog from "./CartDeliveryDialog";
import CartAlertDialog from "./CartAlertDialog";
import { GreyButton } from "../PhaseBStyles/style";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CartDeleteDialog } from "@/components/CartDeletDialog";

export default function CartEmpty() {
  const navigate = useNavigate();

  const [isDelete, setIsDelete] = useState(false);

  const deleteParts = useSelector((state) => {
    return state.cartReducer.deleteItems;
  });

  useEffect(() => {
    deleteParts.length && setIsDelete(true);
  }, []);

  return (
    <>
      <Grid container>
        <Breadcumb
          routes={[
            { name: "TOP", link: "/" },
            { name: "カート", link: "" },
          ]}
        />
        <PageTitle title="カート" />
      </Grid>
      <Grid container mt={2} justifyContent="center">
        <Grid item xs={12} md={4} textAlign="center">
          <Typography className="font-size-24 opacity-80 mb-3 mt-5" component="h5" variant="h5">
            まだ商品がありません
          </Typography>
          <Typography className="font-size-14 opacity-80 mb-8" mb={8} component="h5" variant="h5">
            商品をカートに入れるとここに表示されます
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={8} md={3}>
            <GreyButton
              fullWidth
              className="font-weight-700"
              onClick={() => {
                navigate("/");
              }}
              data-cy="searchForProductsButton"
            >
              商品を検索する
            </GreyButton>
          </Grid>
        </Grid>
        <Grid item md={12} className="mt-5">
          <CartPolicyDialog></CartPolicyDialog>

          <CartDeliveryDialog></CartDeliveryDialog>
          <CartAlertDialog></CartAlertDialog>
        </Grid>
      </Grid>
      {deleteParts?.length > 0 && (
        <CartDeleteDialog open={isDelete} setOpen={setIsDelete} cartItems={deleteParts} />
      )}
    </>
  );
}
