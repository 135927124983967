import { useGetUserMypageInfo } from "@/apis/prev/user/user/user";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { getCookie } from "@/utils/cookieUtils";
import ENVS from "@/constants/envs";

const GtmProvider = ({ children }) => {
  const isLoggedIn = !!getCookie("access_token");
  const gtmId = ENVS.GTM_KEY;

  const { data, isSuccess } = useGetUserMypageInfo({
    query: {
      enabled: isLoggedIn,
    },
  });

  useEffect(() => {
    // gtmIdが空でない場合にのみ、GTM 初期化（初回レンダリング時のみ実行）
    if (gtmId) {
      TagManager.initialize({ gtmId });
    }
  }, [gtmId]);

  useEffect(() => {
    if (isSuccess && data) {
      window.dataLayer = window.dataLayer || [];
      // ログイン時はユーザIDを追加
      window.dataLayer.push({
        user_id: data.data.user.user_id,
      });
    }
  }, [isSuccess, data]);

  return children;
};

export default GtmProvider;
