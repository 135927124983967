import {
  ADD_FAVOURITE_PRODUCT_ACTION,
  ADD_FAVOURITE_PRODUCT_SAGA,
  DELETE_FAVOURITE_PRODUCT_ACTION,
  DELETE_FAVOURITE_PRODUCT_SAGA,
  GET_FAVOURITE_PRODUCT_LIST_ACTION,
  PUT_FAVOURITE_PRODUCT_LIST_SAGA,
} from "./constants";
import { put, takeLatest, call } from "redux-saga/effects";
import {
  ADD_FAVOURITE_PRODUCT_API,
  DELETE_FAVOURITE_PRODUCT_API,
  GET_FAVOURITE_PRODUCTS_API,
} from "@/utils/apiConstants";
import instance from "@/utils/axiosInstance";
import { toastr } from "react-redux-toastr";
import _ from "lodash";
import { formatDataForGtm } from "@/utils/gtmUtils";
import { getCookie } from "@/utils/cookieUtils";

function getFavouriteProductListSagaAPICall(payload) {
  // isLoading(true);
  payload.payload.isLoading(true);
  return instance
    .get(GET_FAVOURITE_PRODUCTS_API, {
      params: { now_on_sale_flg: payload.payload.now_on_sale_flg },
    })
    .then((response) => {
      //   isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      //   isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getFavouriteProductListSaga = function* (payload) {
  let response;
  try {
    response = yield call(getFavouriteProductListSagaAPICall, payload);
    if (_.get(response, "result", false)) {
      yield put({
        type: PUT_FAVOURITE_PRODUCT_LIST_SAGA,
        payload: _.get(response, "data"),
      });
      if (response.data.item.length > 0) {
        payload.payload.setShowFavEmpty(false);
      } else {
        payload.payload.setShowFavEmpty(true);
      }
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error("Error : ", error);
  }
  payload.payload.isLoading(false);
};

function addFavouriteProductSagaAPICall(payload) {
  return instance
    .post(ADD_FAVOURITE_PRODUCT_API, payload.payload)
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const addFavouriteProductSaga = function* (payload) {
  const accessToken = getCookie("access_token");
  if (!accessToken) window.location.href = "/auth/login";

  let response;
  let isLoading = payload.payload.isLoading;
  try {
    isLoading(true);
    response = yield call(addFavouriteProductSagaAPICall, payload.payload);
    if (_.get(response, "result", false)) {
      yield put({
        type: ADD_FAVOURITE_PRODUCT_SAGA,
        payload: { flag: 1, favouriteItem: response.data },
      });
      //gtmTag
      window.dataLayer.push({
        event: "add_to_wishlist",
        event_scope: {
          currency: "JPY",
          ...formatDataForGtm(payload.payload.productData),
        },
      });
      toastr.success("", _.get(response, "message"));
    } else {
      toastr.error("", _.get(response, "message"));
    }
    payload.payload.callableAction();
    payload.payload.setEnableClick(true);
  } catch (error) {
    console.error("Error : ", error);
  } finally {
    isLoading(false);
  }
};

function deleteFavouriteProductSagaAPICall(payload) {
  return instance
    .delete(DELETE_FAVOURITE_PRODUCT_API, {
      params: payload.payload,
    })
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const deleteFavouriteProductSaga = function* (payload) {
  const accessToken = getCookie("access_token");
  if (!accessToken) window.location.href = "/auth/login";

  let response;
  let isLoading = payload.payload.isLoading;
  try {
    isLoading(true);
    response = yield call(deleteFavouriteProductSagaAPICall, payload.payload);
    if (_.get(response, "result", false)) {
      yield put({
        type: DELETE_FAVOURITE_PRODUCT_SAGA,
        payload: { flag: 0, favouriteItem: payload.payload.payload },
      });
      toastr.success("", _.get(response, "message"));
      //gtmTag
      window.dataLayer.push({
        event: "remove_from_wishlist",
        event_scope: {
          currency: "JPY",
          ...formatDataForGtm(payload.payload.productData),
        },
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
    payload.payload.callableAction();
    payload.payload.setEnableClick(true);
  } catch (error) {
    console.error("catch --->");
  } finally {
    isLoading(false);
  }
};

function* favoriteProductSaga() {
  yield takeLatest(GET_FAVOURITE_PRODUCT_LIST_ACTION, getFavouriteProductListSaga);
  yield takeLatest(DELETE_FAVOURITE_PRODUCT_ACTION, deleteFavouriteProductSaga);
  yield takeLatest(ADD_FAVOURITE_PRODUCT_ACTION, addFavouriteProductSaga);
}

export default favoriteProductSaga;
