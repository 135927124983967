import instance from "@/utils/axiosInstance";
import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toastr } from "react-redux-toastr";
import {
  DELETE_INCHARGE_A22_A12_API,
  GET_INCHARGELIST_A22,
  POST_USER_INCHARGE_A22_API,
  PUT_USER_INCHARGE_A22_API,
  USER_MYPAGE_INFO_A12_API,
} from "@/utils/apiConstants";

//担当者取得
export const useGetInchargeList = (
  { limit }: { limit?: string | undefined } = { limit: undefined },
) => {
  const getInchargeList = async () => {
    try {
      const response = await instance.get(GET_INCHARGELIST_A22, { params: { limit: limit } });
      return _.get(response, "data.in_charge", {});
    } catch (error) {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    }
  };

  const query = useQuery({
    queryKey: [GET_INCHARGELIST_A22, limit],
    queryFn: getInchargeList,
  });

  return query;
};

//担当者削除
export const useDeleteIncharge = () => {
  const queryClient = useQueryClient();

  const deleteIncharge = async (incharge_id: number) => {
    try {
      const response = await instance.put(DELETE_INCHARGE_A22_A12_API, { incharge_id });
      return response;
    } catch (error) {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    }
  };

  const mutation = useMutation({
    mutationFn: ({ inchargeId }: { inchargeId: number }) => deleteIncharge(inchargeId),
    onSuccess: () => {
      toastr.success("担当者の削除完了", "担当者の削除に成功しました");
      queryClient.invalidateQueries({ queryKey: [GET_INCHARGELIST_A22] });
      queryClient.invalidateQueries({ queryKey: [USER_MYPAGE_INFO_A12_API] });
    },
    onError: (error) => {
      toastr.error("", _.get(error, "message", ""));
    },
  });
  return mutation;
};

//担当者追加
export const useRegisterIncharge = () => {
  const queryClient = useQueryClient();
  const registerIncharge = async (name: string, position: string) => {
    try {
      const response = await instance.post(POST_USER_INCHARGE_A22_API, { name, position });
      return response;
    } catch (error) {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    }
  };

  const mutation = useMutation({
    mutationFn: ({ name, position }: { name: string; position: string }) =>
      registerIncharge(name, position),
    onSuccess: (data) => {
      toastr.success("担当者の追加完了", "担当者の追加に成功しました");
      queryClient.invalidateQueries({ queryKey: [GET_INCHARGELIST_A22] });
      queryClient.invalidateQueries({ queryKey: [USER_MYPAGE_INFO_A12_API] });
    },
    onError: (error) => {
      toastr.error("", _.get(error, "message", ""));
    },
  });
  return mutation;
};

//担当者編集
export const useUpdateIncharge = () => {
  const queryClient = useQueryClient();
  const updateIncharge = async (incharge_id: number, name: string, position: string) => {
    try {
      const response = await instance.put(PUT_USER_INCHARGE_A22_API, {
        incharge_id,
        name,
        position,
      });
      return response;
    } catch (error) {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    }
  };

  const mutation = useMutation({
    mutationFn: ({
      inchargeId,
      name,
      position,
    }: {
      inchargeId: number;
      name: string;
      position: string;
    }) => updateIncharge(inchargeId, name, position),
    onSuccess: (data) => {
      toastr.success("担当者の編集完了", "担当者の編集に成功しました");
      queryClient.invalidateQueries({ queryKey: [GET_INCHARGELIST_A22] });
      queryClient.invalidateQueries({ queryKey: [USER_MYPAGE_INFO_A12_API] });
    },
    onError: (error) => {
      toastr.error("", _.get(error, "message", ""));
    },
  });
  return mutation;
};
