import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_MASTER_PREFECTURE_INFO,
  GET_MASTER_PREFECTURE_INFO_REDUCER,
  POST_USER_SHIPPING_DATA_DZONE_A20,
  PUT_USER_SHIPPING_DATA_DZONE_A21,
  PREFECTURE_INFO_DATA_DZONE,
  PREFECTURE_INFO_DATA_DZONE_REDUCER,
} from "./constants";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import {
  COMPANT_FIND_ZIP_CODE_API,
  POST_USER_SHIPPING_A20_API,
  PUT_USER_SHIPPING_A21_API,
  GET_PREFECTURE,
} from "@/utils/apiConstants";
import instance from "@/utils/axiosInstance";

function userMyPageInfoImageHandler(data, isLoading) {
  isLoading(true);
  return instance
    .get(COMPANT_FIND_ZIP_CODE_API, { params: data })
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getMasterPrefrenceInfoHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  try {
    const response = yield call(userMyPageInfoImageHandler, payload.payload.pincode, isLoading);
    if (_.get(response, "result", false)) {
      yield put({
        type: GET_MASTER_PREFECTURE_INFO_REDUCER,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  }
};

function postUserShippingInfo(data, isLoading) {
  isLoading(true);
  return instance
    .post(POST_USER_SHIPPING_A20_API, data)
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const postUserShippingInfoHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let navigate = payload?.payload?.navigate;
  let origin = payload.payload.pageReferrer;
  let response;
  try {
    response = yield call(postUserShippingInfo, payload.payload.data, isLoading);
    if (_.get(response, "result", false)) {
      if (origin) {
        origin !== "新規追加" && navigate(origin);
      } else {
        navigate("/mypage");
      }
    } else {
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  }
};

function updateUserShippingInfo(data, isLoading) {
  isLoading(true);
  return instance
    .put(PUT_USER_SHIPPING_A21_API, data)
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const updateUserShippingInfoHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  try {
    const response = yield call(updateUserShippingInfo, payload.payload.data, isLoading);
  } catch (error) {
    toastr.error("エラーが発生しました。");
  }
};

function getPrefecture(data, isLoading) {
  isLoading(true);
  return instance
    .get(GET_PREFECTURE, { params: data })
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getPrefectureHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let response;
  try {
    response = yield call(getPrefecture, payload.payload.data, isLoading);
    if (_.get(response, "result", false)) {
      yield put({
        type: PREFECTURE_INFO_DATA_DZONE_REDUCER,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  }
};

function* shippingSaga() {
  yield takeLatest(GET_MASTER_PREFECTURE_INFO, getMasterPrefrenceInfoHandler);
  yield takeLatest(POST_USER_SHIPPING_DATA_DZONE_A20, postUserShippingInfoHandler);
  yield takeLatest(PUT_USER_SHIPPING_DATA_DZONE_A21, updateUserShippingInfoHandler);
  yield takeLatest(PREFECTURE_INFO_DATA_DZONE, getPrefectureHandler);
}

export default shippingSaga;
